.personacard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 1rem;
  /* background-color: crimson; */
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-top: 0.25em;
  align-items: flex-start;
  height: 75%;
  /* max-width: 68px; */
}

.personacard:hover {
  position: relative;
  color: var(--nav_linkhover_color);
  background-color: var(--nav_icon_color);
}

.logo_persona {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: lightsalmon;
  align-items: center;
  height: 40px;
  align-self: center;
}

.persona_name {
  width: 100%;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  align-content: center;
  font-weight: bold;
  color: var(--main_header_text_color);
}

.persona_name_selected {
  /* float: left; */
  width: 100%;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  align-content: center;
  font-weight: bold;
  /* display: flex; */
  color: var(--main_header_text_selected_color);
}

.persona_name:hover {
  overflow: visible;
  color: var(--nav_linkhover_color);
  background-color: var(--nav_icon_color);
}

.persona_name_selected:hover {
  overflow: visible;
  color: var(--nav_linkhover_color);
  background-color: var(--nav_icon_color);
}

.logo_persona p {
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: 2s;
}

.logo_persona:hover p {
  display: inline-block;
  width: auto;
  overflow: visible;
  white-space: normal;
}

.flag_btn {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  margin-left: 0px;
}

/* .selected {
  background-color: #008080;
} */

/* .logo_persona: selected {
  background-color: #008080;
} */

.selected {
  background-color: #008080;
}

.logo_persona .header .selected {
  color: blue;
  background-color: cadetblue;
}

.add_new_persona {
  cursor: pointer;
  width: 90px;
  border-color: var(--main_header_image_border_color);
  background-color: var(--main_header_image_background_color);
  color: var(--main_header_text_color);
  display: block;
  box-shadow: 10px 5px 40px 20px darken(#341cac, 5%);
  transition: 0.25s;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}

.sidebarIcon {
  position: absolute;
  color: var(--nav_icon_color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  right: 0;
  margin-right: 10px;
  background-color: var(--nav_bg_color);
  font-size: 28px;
  border-radius: 10px;
}

.add_new_persona:hover {
  color: var(--nav_linkhover_color);
  background-color: var(--nav_icon_color);
}

.sidebarIcon:hover {
  color: var(--nav_linkhover_color);
}

.persona-card {
  width: 280px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--main_header_card_border_color);
  background-color: var(--main_header_card_background_color);
  color: var(--main_header_text_color);
  display: block;
  /* margin: 10px auto; */
  box-shadow: 10px 5px 40px 20px darken(#341cac, 5%);
  transition: 0.25s;
  /* height: calc(100% - 0px); */
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  cursor:pointer;
}

.persona-card[type="medium"] {
  width: 90px;
  /* margin: 10px auto; */
  /* background-color: slateblue; */
  /* padding: 0px; */
}

.persona-card[type="small"] {
  width: 70px;
  /* margin: 10px auto; */
  /* padding: 0px; */
}

.persona-card[type="medium"] h2 {
  font-size: 1rem;
}

.persona-card[type="small"] h2 {
  font-size: 0.85rem;
}

.persona-card:hover {
  box-shadow: 10px 5px 40px 20px darken(#341cac, 10%);
  /* cursor: pointer; */
}

.persona-card-selected {
  background-color: var(--main_header_card_background_selected_color);
  border-color: var(--main_header_card_border_selected_color);
  border-width: 4px;
  cursor: default;
}

.persona-card-header {
  display: inline-block;
  border-radius: 50%;
  border-style: solid;
  /* border-color: var(--main_header_image_border_color);
  border-width: 2px; */
  z-index: 99;
  align-items: center;
  margin-top: 0;
  padding: 2px;
  background-color: var(--main_header_image_background_color);
  /* height: 3.3rem;
  width: 3.3rem; */
  overflow: hidden;
  width: 48px;
  height: 48px;
  filter: grayscale(1);
}
.persona-card-header-wrapper {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  /*justify-content: space-around; */
  /* align-items: center; */
  /* flex-direction: column;  */
  justify-self: center;
  align-self: center;
  border: 2px solid var(--main_header_image_border_color);
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
}

.persona-card-header-unselected {
  display: inline-block;
  border-radius: 50%;
  /* border-style: solid; */
  /* border-color: var(--main_header_image_border_color); */
  /* border-width: 2px; */
  border: none;
  z-index: 99;
  align-items: center;
  margin-top: 0;
  padding: 2px;
  background-color: var(--main_header_image_background_color);
  overflow: hidden;
  width: 48px;
  height: 48px;
  /* filter: grayscale(1); */
  cursor: pointer;
}

.persona-card-header-selected {
  border-radius: 50%;
  background-color: var(--main_header_image_background_selected_color);
  height: 56px;
  width: 56px;
  border-style: solid;
  border-width: 4px;
  border-color: var(--main_header_image_border_selected_color);
}

.persona-pics {
  /* display: inline-block; */
  /* border-radius: 50%; */
  /* border-style: solid; */
  /* border-color: var(--main_header_image_border_color); */
  /* border-width: 2px; */
  border: 1px solid darkgreen;
  z-index: 99;
  align-items: center;
  margin-top: 0;
  padding: 2px;
  background-color: var(--main_header_image_background_color);
  overflow: hidden;
  width: 90px;
  height: 110px;
  /* filter: grayscale(1); */
}

.persona-pics-internal {
  display: flex;
  position:relative;
  align-items: center;
  border: none;
  /* margin-top: 12px; */
  cursor: pointer;
  /* border-radius: 50%; */
  /* border-style: solid; */
  /* border-color: var(--main_header_image_border_color); */
  /* border-width: 2px; */
  /* border: 5px solid darkgreen; */
  /* z-index: 99;
  align-items: center;
  margin-top: 0;
  
  background-color: var(--main_header_image_background_color);
  overflow: hidden;
  width: 64px;
  height: 64px; */
  /* filter: grayscale(1); */
}
/* 
.persona-pics-internal:hover {
  transform: translate(0px, -4px);
  cursor: pointer;
} */

.carousel-pics-close{
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    right: 0;
    /* border-radius: 50%;
    border: 1px solid var(--modal_border_color);
    background-color: lightgray; */
    /* color:red; */

    /* color: var(--modal_text_color); */
}

.carousel-pics-close:hover{
  /* transform: translate(0px, -4px); */
  cursor: pointer;
  color:red;
}

.pics-carousel {
  /* padding-left: 8px; */
  height: 7rem;
  width: 5rem;
  object-fit: cover;
}

.persona-frame-pic {
  display: flex;
  flex-direction: row;
  /* flex-grow:1; */
  align-items: center;
  min-width: calc(100% - 0.625rem);
  max-width: 100%;
  padding: 0.25rem;
  background-color: transparent;
  /* background-color: var(--main_header_background_color); */
  /* margin-left: 12px; */
}

.persona-card-header[type="medium"] {
  height: fit-content;
}

.persona-card-header[type="small"] {
  height: 40px;
}

.persona-card-header--title {
  text-transform: uppercase;
  margin: 0;
}

.persona-card-header--title[type="medium"] {
  font-size: 1.2em;
}

.persona-card-header--title[type="small"] {
  font-size: 0.8em;
}

.persona-card-body {
  padding-top: 15px;
  padding-bottom: 15px;
  word-wrap: break-word;
  /* width: 100%; */
  height: calc(100% - 200px);
  padding-left: 5px;
  margin-left: 4px;
  margin-right: 4px;
}

.persona-card-body[type="medium"] {
  padding: 0px;
  height: calc(100% - 108px);
}

.persona-card-body[type="small"] {
  padding: 0px;
  height: calc(100% - 48px);
}

.persona-card-body[type="medium"] h2 {
  margin: 0px;
}

.persona-card-body[type="small"] h2 {
  margin: 0px;
}

.persona-card-header--pre-title {
  font-size: 11px;
  font-weight: 600;
  color: grey;
}

.persona-card-header--pre-title[type="medium"] {
  margin: 4px;
  font-size: 10px;
}

.persona-card-header--pre-title[type="small"] {
  margin: 4px;
  font-size: 8px;
}

.persona-card-body-content {
  padding-bottom: 40px;
  font-size: 13px;
  line-height: 1.8;
}

.persona-card-body-content[type="medium"] {
  margin-left: 2px;
  margin-right: 2px;
  padding-bottom: 10px;
  font-size: 11px;
  line-height: 1.4;
}
.persona-card-body-content[type="small"] {
  margin-left: 2px;
  margin-right: 2px;
  padding-bottom: 4px;
  font-size: 10px;
  line-height: 1.2;
}

.persona-topic-count {
  background-color: var(--unread_topic_indicator_background_color);
  color: var(--unread_topic_indicator_text_color);
  justify-self: end;
  align-self: end;
  font-size: 0.95rem;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: var(--border_radius_xtra_large);
  opacity: 1;
}

.persona-topic-count-invisible {
  background-color: var(--unread_topic_indicator_background_color);
  color: var(--unread_topic_indicator_text_color);
  justify-self: end;
  align-self: end;
  font-size: 0.95rem;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: var(--border_radius_xtra_large);
  opacity: 0;
}

.persona-card-button-primary {
  border: none;
  box-shadow: none;
  font-family: inherit;
  background-color: "transparent";
  color: "#blue";
  font-size: 15px;
  transition: 0.25s;
}

.persona-card-primary:hover {
  transform: translate(4px, 0);
}

.persona-card-primary[type="medium"] {
  font-size: 12px;
}

.persona-card-primary[type="small"] {
  font-size: 10px;
}

.persona-card-primary[type="medium"]:hover {
  transform: translate(3px, 0);
}

.persona-card-primary[type="small"]:hover {
  transform: translate(2px, 0);
}

.designer-link {
  color: #fff;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  display: block;
}

.i {
  padding-right: 4px;
}

.create_community_button {
  display: flex;
  flex-direction: column;
}

.menu-rgt-click {
  font-size: 1rem;
  background-color: var(--modal_background_color);
  border: 1px solid var(--menu_border_color);
  border-radius: var(--border_radius_small);
  padding: 5px 8px 5px 8px;
  width: fit-content;
  height: auto;
  margin: 0;
  z-index: 999;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  /* box-shadow: -2rem 2rem 2rem rgba(black, 0.2); */
  box-shadow: 0 0 20px 0 var(--main_header_background_color);
  opacity: 1;
  transition: opacity 0.5s linear;
  text-align: left;
}

.stroke-custom {
  /* path { */
    stroke-width: 1.2px !important;
  /* } */
}

.profilecard{
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 0.5rem; */
  width: 2.5rem;
  height: 2.5rem;
  border: var(--main_header_image_border_color) 2px solid;
  border-radius: 50%;
}
