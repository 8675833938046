.UI-new-msg-count-on {
  position: sticky;
  bottom: 6%;
  float: right;
  color: var(--unread_topic_indicator_text_color);
  background-color: var(--unread_topic_indicator_background_color);
  border-radius: 50%;
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.85rem;
  opacity: 0.5;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  z-index: 100;
}

.UI-new-msg-count-off {
  display: none;
}

.UI-new-msg-button-text {
  color: white;
  font-size: 100%;
  font-style: normal;
}

.UI-main-screen-fab-area {
  display: flex;
  flex-direction: flex-row;
  border-top: 3px solid var(--main_header_separator_line_color);
  align-content: center;
  justify-content: space-evenly;
  min-width: calc(100% - 0.625rem);
  max-width: calc(100%);
  padding-top: 0.3rem;
  padding-bottom: 0.25rem;
  height:44px;
}

.UI-main-screen-fab {
  -webkit-tap-highlight-color: transparent;
  outline: 2px solid var(--main_screen_background_color);
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  /* transition: background var(--speed); */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.UI-main-screen-fab-active {
  background:rgb(246, 157, 157);
  /* color:white; */
  color:black;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
/* transition: background var(--speed); */
}

.UI-main-screen-fab-mobile {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: 2px solid var(--main_screen_background_color);
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}

.UI-main-screen-fab-mobile:focus {
  /* -webkit-tap-highlight-color: transparent; */
    outline: 0;
    border:none;
    color: rgba(0, 0, 0, 0);
  /* outline: 2px solid var(--main_screen_background_color);  */
}

.UI-main-screen-fab-mobile-active {
  background:rgb(246, 157, 157);
  /* color:white; */
  color:black;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
/* transition: background var(--speed); */
}

.UI-main-screen-fab-active:hover{
    background-color: var(--menu_background_selected_color);
    color: var(--menu_text_selected_color);
}

.UI-main-screen-fab:hover {
  background-color: var(--menu_background_selected_color);
  color: var(--menu_text_selected_color);
}

.UI-main-screen-fab span {
  mix-blend-mode: initial;
}

.unread-element {
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  /* transition: background var(--speed); */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
/* background-color: var(--menu_background_selected_color); */
/* color:var(--menu_text_selected_color); */
  /* width: 100px;
  height: 50px; */
  /* overflow: hidden; */
  white-space: nowrap;
  /* mix-blend-mode: difference; */
}

/* .unread-element::before {
  content: '';
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  color: #000;
  mix-blend-mode: difference;
} */

.unread-element:hover{
  background-color: var(--menu_background_selected_color);
  color: var(--menu_text_selected_color);
}

/* .unread-element:hover::before {
  background-color: #f00;
  mix-blend-mode: initial;
}

.unread-element:hover::after {
  color: #f00;
}

.unread-element.large {
  color: #f00;
  mix-blend-mode:initial;
} */

.unread-element-active {
  /* background-color: var(--unread_topic_indicator_background_color); */
  /* background-color:#E10000; */
  background:rgb(246, 157, 157);
  /* color:white; */
  color:black;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  /* opacity:0.5; */
}

.unread-element-active:hover {
  background-color: var(--menu_background_selected_color);
  color: var(--menu_text_selected_color);
}

.UI-unread-element-mobile {
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}

.UI-unread-element-mobile-active {
  background:rgb(246, 157, 157);
  color:black;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  margin: 0.13rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}


.info-message{
  position: absolute;
                  top: 0px;
                  left: 0px;
                  text-align: justify;
                  margin: 1rem;
}