
.col-1 {
  flex-basis: 100%;
  min-width: 300px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh);
  border-right: var(--column_divider_color) var(--column_divider_width) solid;
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.col-2 {
  min-width: 300px;
  height: calc(100vh);
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.col-3 {
  height: calc(100vh);
  /* padding-left: 0.75rem; */
  margin:0;
  padding:0rem 0rem 0rem 0.75rem;
  box-sizing: border-box;
}

.UISs {
  display: grid;
  background-color: var(--main_screen_background_color);
  text-align: center;
  width: 100%;
  color: var(--main_screen_text_color);
  overflow: hidden;
  position: fixed;
}

@media screen and (min-width: 0px) and (max-width: 300px) {
  .col-1 {
    display:flex;
  }
}

@media screen and (min-width: 301px) and (max-width: 600px) {
  .col-1 {
    display: flex;
  }
}

@media screen and (min-width: 601px) {
  .col-1, .col-2 {
    flex-basis: 50%;
  }
}

@media (min-width: 301px) {
  .UISs {
    grid-template-columns: minmax(300px,600px);
    grid-template-rows: max-content;
  }
}

@media (min-width: 601px) {
  .UISs {
    grid-template-columns:  minmax(300px, 500px) minmax(300px, 500px);
    grid-template-rows: max-content;
  }

}

