:root {
  --main_header_background_color: "#f2f609";
  --menu_button_border_color: "#adf0f0";
  --menu_button_icon_color: "#ec3232";
  --main_header_bubble_border_color: "#adf0f0";
  --main_header_bubble_background_color: "#add505";
  --main_header_card_border_color: "#adf0f0";
  --main_header_card_background_color: "#f8aaaa";
  --main_header_card_border_selected_color: "#0c0d0d";
  --main_header_card_background_selected_color: "#f35858";
  --main_header_image_border_color: "#adf0f0";
  --main_header_image_background_color: "#f8aaaa";
  --main_header_image_border_selected_color: "#0c0d0d";
  --main_header_image_background_selected_color: "#f35858";
  --main_header_text_color: "#8e92f5";
  --main_header_separator_line_color: "#CCCCCC";
  --main_screen_background_color: "#c2cda2";
  --main_screen_background_archive_color: "#EFE8E1";

  --main_screen_text_color: "#19a32a";
  --main_screen_topic_separator_line_color: "#c2cda2";
  --main_screen_topic_type_indicator_border_color: "#333333";
  --main_screen_topic_type_indicator_text_color: "#121212";

  --main_screen_topic_type_public_background_color: "#FFFFFF";
  --main_screen_topic_type_private_background_color: "#FFFFFF";
  --main_screen_topic_type_community_background_color: "#FFFFFF";
  --main_screen_topic_type_job_background_color: "#FFFFFF";
  --main-screen_topic_type_dialog_background_color: "#FFFFFF";
  --main_screen_topic_type_default_background_color: var(--main_screen_background_color);

  --search_fab_border_color: "#cccccc";
  --search_fab_background_color: "#121212";
  --add_topic_fab_border_color: "#cccccc";
  --add_topic_fab_background_color: "#121212";

  --menu_border_color: "#0fe65a";
  --menu_text_color: "#f51313";
  --menu_text_selected_color: "#a8a3a3";
  --menu_background_color: "#121212";
  --menu_background_selected_color: "#4e4e4e";

  --modal_border_color: "#0fe65a";
  --modal_text_color: "#f51313";
  --modal_background_color: "#FFFFFF";
  --modal_header_background_color: "#ADD5D5";
  --modal_header_text_color: "#4E4E4E";
  --modal_footer_background_color: "#ADD5D5";
  --modal_footer_text_color: "#4E4E4E";

  --topic_header_text_color: "#efef6b";
  --topic_header_background_color: "#91e316";
  --topic_header_separator_line_color: "#4e4e4e";
  --topic_screen_background_color: "#9235E0";
  --topic_border_color: "#008080";
  --topic_bubble_color: "#FFFFFF";

  --receive_bubble_border_color: "#f09f70";
  --receive_bubble_background_color: "#d15d1a";
  --receive_bubble_text_color: "#4e4e4e";
  --receive_bubble_time_color: "#999999";
  --receive_bubble_name_color: "#999999";
  --quoted_message_background_color:"#E1D5C7";

  --send_bubble_border_color: "#f09f70";
  --send_bubble_background_color: "#999999";
  --send_bubble_text_color: "#555555";
  --send_bubble_time_color: "#999999";
  --send_bubble_name_color: "#999999";

  --system_bubble_border_color: "#008800";
  --system_bubble_background_color: "#008800";
  --system_bubble_text_color: "#008800";
  --system_bubble_time_color: "#008800";
  --system_bubble_name_color: "#008800";

  --button_border_color: "#008800";
  --button_background_color: "#008800";
  --button_background_gradient_color: "#00808050";
  --button_text_color: "#008800";
  --button_border_color_selected: "#008800";
  --button_background_color_selected: "#008800";
  --button_background_gradient_color_selected: "#add5d595";
  --button_text_color_selected: "#008800";

  --toggle_switch_border_color: "#008800";
  --toggle_switch_background_color: "#CCCCCC";
  --toggle_switch_background_selected_color: "#008800";

  --hover_text_color: "#8F5FA7";

  /* --select_text_color: "#f51313";
  --text_background_color: "#a8a3a3";
  --menu_text_color: "#f51313";
  --menu_text_selected_color: "#4e4e4e"; */

  --topic_description_text_color: "#008800";
  --topic_description_background_color: "#008800";
  --topic_pinned_message_background_color: "#e1eeb5";

  --send_bar_hint_color: "#008800";
  --topic_at_symbol_color:"#E10000";
  --send_bar_border_color: "#008800";
  --send_bar_background_color: "#008800";
  --send_bar_text_color: "#008800";
  --send_button_background_color: "#8a6060";

  --border_radius_xtra_large: 1rem;
  --border_radius_large: 0.75rem;
  --border_radius_medium: 0.5rem;
  --border_radius_small: 0.25rem;

  --speed: 500ms;

  --column_divider_color: grey;
  --column_divider_width: 0.13rem;

  --unread_topic_indicator_background_color: "#a80053";
  --unread_topic_indicator_text_color: "#FFFFFF";
  --unread_message_indicator_background_color: "#CCCCCC";
  --unread_message_indicator_text_color: "#333333";

  --logo_background_transparency: 0;
  --whisper_background_transparency: 0.9;

  --message_time_font_size: 0.75rem;
  --bubble_text_fontsize: 1.1rem;

  --service_message_fontsize: 1rem;

  --topic_name_main_screen_font: "Roboto400";
  --topic_name_topic_bar_font: "Roboto400";
  --body_typeface_font: "Roboto400";
  --body_typeface_fonttest:"Roboto100";

  --box_shadow:2px 4px 8px rgba(0, 0, 0, 0.25);
  --button_opacity:0.5;
}

body {
  overscroll-behavior-y: contain;
}

.UI {
  display: grid;
  background-color: var(--main_screen_background_color);
  text-align: center;
  width: 100%;
  color: var(--main_screen_text_color);
  overflow: hidden;
  /* height: 100vh; */
  position: fixed;

  /* height:99%; */
}

.UI-frame {
  display: flex;
  flex-direction: column;
  width: (100%);
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh);
  border-right: var(--column_divider_color) var(--column_divider_width) solid;
}

.UI-login-frame {
  width: 100%;
  max-width: 31.25rem;
  flex-direction: row;
  justify-content: space-around;
  min-height: 8vh;
}

.UI-theme-item-list {
  /* height: 18.75rem; */
  overflow: auto;
}

.UI-theme-item {
  display: grid;
  grid-template-columns: 80% 20%;
  grid-gap: 0.25rem;
  align-items: center;
}

.UI-theme-item-fonts {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 0.25rem;
  align-items: center;
  width: 100%;
}

.UI-theme-item-themes {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items:start;
  margin-top: 0.5rem;
  height:2rem;
  align-items: center;
}

.UI-dropdown-theme-icons{
  right:1.8rem;
  border: 1px solid black;
  border-radius: var(--border_radius_small);
  z-index: 99;
  overflow: hidden;
  box-shadow:var(--box_shadow);
  background-color: var(--menu_background_color);
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 0.1rem;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  animation: slideIn var(400ms) ease-in-out forwards;
}

.UI-theme-item-radio{
  text-align:left;
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
  white-space:nowrap;
}

.UI-theme-item-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.UI-theme-color {
  max-width: 20%;
}

.UI-persona-frame {
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: 1fr auto; */
height:100px;
  flex-direction: row;
  align-items: center;
  width: calc(100% - (2 * var(--column_divider_width) + 0.5rem));
  padding: 0.25rem;
  background-color: var(--main_header_background_color);
  border-bottom-style: solid;
  border-bottom-color: var(--main_header_separator_line_color);
}

.UI-persona-carousel {
  border: 0.15rem solid var(--main_header_bubble_border_color);
  color: var(--main_header_text_color);
  background-color: var(--main_header_bubble_background_color);
}

.UI-persona-frame-icon {
  margin-left: 0.5rem;
  color: var(--menu_button_icon_color);
  border-color: var(--menu_button_border_color);
  font-size: 1.75rem;
  height: fit-content;
  cursor: pointer;
}

/* .UI-horizontal-rule {
  border-style: solid;
  height: 0.13rem;
  width: 100%;
  color: var(--main_header_separator_line_color);
  background-color: var(--main_header_separator_line_color);
} */

.UI-topic-list-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 0.625rem);
  max-width: calc(100%);
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  /* max-height: 82%;
  height:inherit; */
}

.UI-topic-list-container-search {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 0.625rem);
  max-width: calc(100%);
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 0.5rem;
  overflow: auto;
  /* max-height: 45vh; */
}

.UI-topic-list-container-hidden {
  display: none;
}

.UI-unread-topics-container {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  max-width: 100%;
  overflow:hidden;
}

.UI-unread-topics-container[type="small"] {
  width: 99%;
  margin-left: 4px;
}

.UI-unread-topics-container[type="none"] {
  width: 100%;
}

.UI-unread-topics {
  font-size: 1rem;
  padding-right: 0.25rem;
}

.UI-unread-topics-none {
  display: none;
}

.UI-topics-icon {
  font-size: 0.65rem;
  margin-right: 0.2rem;
  border: var(--main_screen_topic_type_indicator_border_color) 0.06rem solid;
  color: var(--main_screen_topic_type_indicator_text_color);
  border-radius: var(--border_radius_small);
  height: fit-content;
  padding: 0.13rem;
  width: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: center;
  height:1.5rem;
}

/* .UI-topics-icon:hover {
  overflow: visible;
  transform: translateY(-5px);
  font-weight: bold;
  border: none; */
/* color: var(--nav_linkhover_color);
  background-color: var(--nav_icon_color); */
/* } */

.UI-topics-icon[type="private"] {
  background-color: var(--main_screen_topic_type_private_background_color);
}
.UI-topics-icon[type="public"] {
  background-color: var(--main_screen_topic_type_public_background_color);
}
.UI-topics-icon[type="dialog"] {
  background-color: var(--main_screen_topic_type_dialog_background_color);
}
.UI-topics-icon[type="hidden"] {
  background-color: var(--main_screen_topic_type_hidden_background_color);
}
.UI-topics-icon[type="community"] {
  background-color: var(--main_screen_topic_type_community_background_color);
}
.UI-topics-icon[type="job"] {
  background-color: var(--main_screen_topic_type_job_background_color);
}

.UI-topic-list {
  width: 100%;
  list-style-type: none;
  height:100%;
}

.UI-topic-list-create-community {
  width: 100%;
  list-style-type: none;
  text-align: left;
}

.UI-topic-list p {
  margin-bottom: 0;
}

.UI-options {
  display:grid;
  grid-template-columns: repeat(auto-fill,4rem);
}

.UI-options-new {
  margin-left: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.checkbox{
accent-color: var(--button_background_color_selected,#007bff);
}

.UI-checkbox-large{
  -ms-transform: scale(1.25); /* IE */
  -moz-transform: scale(1.25); /* FF */
  -webkit-transform: scale(1.25); /* Safari and Chrome */
  -o-transform: scale(1.25); /* Opera */
  transform: scale(1.25);
  margin-right:1rem;
  accent-color: var(--button_background_color_selected,#007bff);
}

.UI-topic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  clear: both;
  direction: ltr;
  max-width: 100%;
}

.UI-topic_forwithpreview{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-left: 2px;
  border-bottom: 0.06rem solid var(--main_screen_topic_separator_line_color);
  border-bottom-style: solid;
  border-bottom-width: 0.06rem;
  font-size: 1.1rem;
}

.UI-topics-forWithPreviewGrid{
  margin-left: 0.2rem;
  margin-right: 0.5rem;
  display:grid;
  grid-template-columns: 4rem minmax(0, 1fr) 1.1rem 1.1rem 4rem 1.5rem 1.1rem;
  align-items: center;
}

.UI-topic-participants {
  display: grid;
  grid-template-columns: 10rem 5rem auto;
  column-gap: 1rem;
  text-align: left;
  align-items: center;
  max-width: 100%;
  margin-left: 0.625rem;
  border-bottom: var(--main_screen_topic_separator_line_color);
  border-bottom-style: solid;
  border-bottom-width: 0.06rem;
  font-size: 1.1rem;
}

.UI-topic-participants-header {
  display: grid;
  grid-template-columns: 10rem 7rem auto;
  column-gap: 1rem;
  text-align: left;
  align-items: center;
  max-width: 100%;
  border-bottom: var(--main_header_separator_line_color);
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  font-size: 1.1rem;
}

.UI-collection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  clear: both;
  direction: ltr;
  max-width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.625rem;
  padding-right: 0.625rem;
  margin-left: 0.625rem;
  border-bottom: var(--main_screen_topic_separator_line_color);
  border-bottom-style: solid;
  border-bottom-width: 0.06rem;
  font-size: 1.1rem;
  min-height: 3.25rem;
  height:3.25rem;
  border-radius: var(--border_radius_large);
  background-color: var(--main_screen_topic_type_default_background_color);
  cursor: "pointer";
}

.UI-collection[type="private"] {
  background-color: var(--main_screen_topic_type_private_background_color);
}

.UI-collection[type="dialog"] {
  background-color: var(--main_screen_topic_type_dialog_background_color);
}

.UI-collection[type="public"] {
  background-color: var(--main_screen_topic_type_public_background_color);
}

.UI-collection[type="community"] {
  background-color: var(--main_screen_topic_type_community_background_color);
}

.UI-collection[type="job"] {
  background-color: var(--main_screen_topic_type_job_background_color);
}

.UI-collection[type="hidden"] {
  background-color: var(--main_screen_topic_type_hidden_background_color);
}

.UI-topic-header {
  position: relative;
  display: grid;
  grid-template-columns: 2.5rem min-content auto 1.5rem 1.5rem 1.5rem;
  grid-template-rows: 100%;
  max-width: 100%;
  top: 0;
  color: var(--topic_header_text_color);
  background-color: var(--topic_header_background_color);
  border-bottom: 0.19rem solid var(--topic_header_separator_line_color);
  border-top-right-radius: var(--border_radius_medium);
  border-top-left-radius: var(--border_radius_medium);
  font-size: 1rem;
  align-items: flex-start;
}

.UI-topic-header-description {
  display: -webkit-flex;
  align-items: center;
  padding:0.13rem 2rem 0.13rem 2rem;
  max-height: 5rem;
  overflow-wrap: break-word;
  overflow-y: auto;
  left: 0;
  text-align: left;
  font-size: 1rem;
  background-color: var(--topic_description_background_color);
  color: var(--topic_description_text_color);
  border-bottom: 0.19rem solid var(--topic_header_separator_line_color);
}

.UI-topic-header-description[type="none"] {
  display: none;
}

.UI-topic-header[type="none"] {
  height: 0;
  border-bottom: none;
}

.UI-topic-header[type="small"] {
  height: 1.75rem;
  align-items: center;
  font-size: 0.8rem;
}

.UI-topic-header[type="medium"] {
  height: 4.5rem;
}

.UI-topic-header[type="large"] {
  height: 5.25rem;
}

.UI-topic-header[type="xlarge"] {
  height: 7.1rem;
}

.UI-topic-header[type="nonewithdescription"] {
  border-bottom: none;
}

.UI-topic-header[type="smallwithdescription"] {
  font-size: 0.8rem;
  border-bottom: 0.06rem solid var(--topic_border_color);
  display: flex;
  flex-direction: row;
  height: 1.75rem;
  align-items: center;
}

.UI-topic-header[type="mediumwithdescription"] {
  font-size: 1rem;
  border-bottom: 0.06rem solid var(--topic_border_color);
}

.UI-topic-header[type="largewithdescription"] {
  height: 5.25rem;
  font-size: 1rem;
  border-bottom: 0.06rem solid var(--topic_border_color);
}

.UI-topic-header[type="xlargewithdescription"] {
  height: 7.1rem;
  font-size: 1rem;
  border-bottom: 0.06rem solid var(--topic_border_color);
}

.UI-topic-title[type="small"] {
  /* left: 0; */
  margin-top:0.125rem;
  font-size: 1.13rem;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-align: left;
  line-height: 1.3em;
}

.UI-topic-title[type="medium"] {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  margin-right: 1.5rem;
  font-size: 1.25rem;
  max-width: 100%;
max-height: 2.8em;
  text-align: left;
  line-height: 1.2em;
  max-lines: 2;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
white-space: pre-wrap;
  word-break: break-word;
}

.UI-topic-title[type="large"] {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  margin-right: 1.5rem;
  font-size: 1.25rem;
  max-width: 100%;
max-height: 2.8em;
  text-align: left;
  line-height: 1.2em;
  max-lines: 2;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
white-space: pre-wrap;
  word-break: break-word;
}

.UI-topic-title[type="xlarge"] {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  /* margin-right: 1.5rem; */
  font-size: 1.25rem;
  max-width: 100%;
  max-height: 4em;
  text-align: left;
  line-height: 1.2em;;
  max-lines: 3;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
white-space: pre-wrap;
  word-break: break-word;
}

.UI-topic-header-names {
  display:grid;
  grid-template-columns: auto min-content min-content min-content;
  max-width:100%;
  align-items: center;
}

/* .UI-topic-header-persona-type { */
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:80%; */
/* } */

/* .UI-topic-header-type {
  font-size: 0.75rem;
  padding: 0.13rem;
  margin-left: 0.25rem;
} */

.UI-topic-header-names-persona {
  position:relative;
  /* display:flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width:100%;
  margin-top:0.25rem; */
  display:grid;
  grid-template-columns: min-content;
  grid-template-rows:auto;
  column-gap:0.5rem;
  row-gap: 0.2rem;
  /* margin-top:0.2rem; */
}

.UI-topic-header-persona {
  /* flex-grow:1;
  flkex-shrink:0;
  margin-right: 0.25rem;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left; */
  /* grid-column: 1/-1; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  text-align: left;
}

.UI-topic-header-tag {
  /* flex-grow:2;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-right: 1rem;
  border: 0.06rem solid var(--topic_header_text_color);
  border-radius: var(--border_radius_small);
  padding: 0.1rem 0.25rem 0 0.25rem; */
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  border: 0.06rem solid var(--topic_header_text_color);
  border-radius: var(--border_radius_small);
  padding: 0.1rem 0.25rem 0 0.25rem;
  white-space: nowrap;
}

.UI-topic-header-type {
  /* flex-grow: 3;
  font-size: 1rem;
  white-space: nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
  margin-left: 0.25rem; */
  display:flex;
  align-items: center;
  font-size: 1rem;
  white-space: nowrap;
}

.UI-topic-header-participants {
  /* font-size: 1rem;
  white-space: nowrap;
  overflow:hidden;
  margin-left: 0.25rem; */
  display:flex;
  align-items: center;
  white-space: nowrap;
}

.UI-topic-dots-menu {
  background-color: transparent;
  font-size: 1rem;
  list-style-type: none;
  margin-top: 0.3rem;
  margin-right: 0.3rem;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-1.5rem) translateY(0rem);
  list-style-type: none;
  transition: filter 300ms;
  z-index: 99;
}

.UI-back-arrow {
  background-color: transparent;
  color: var(--menu_button_icon_color);
  list-style-type: none;
  top: 6px;
  z-index: 99;
  cursor: pointer;
}

.UI-back-arrow-no-header {
  position: absolute;
  border: var(--menu_button_border_color) 0.06rem solid;
  color: var(--menu_button_icon_color);
  list-style-type: none;
  transition: filter 300ms;
  border-radius: 50%;
  background-color: var(--menu_background_color);
  z-index: 99;
  left: 0;
  margin-top: 1px;
  margin-left: 2px;
  cursor: pointer;
}

.UI-topic-dots-menu-no-header {
  position: absolute;
  border: var(--menu_button_border_color) 0.06rem solid;
  list-style-type: none;
  transition: filter 300ms;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
  background-color: var(--menu_background_color);
  z-index: 99;
  cursor: pointer;
}
.UI-topic-dots-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.UI-profile-menu {
  background-color: transparent;
  border: none;
  color: var(--menu_button_icon_color);
  /* display: flex; */
  flex: 1;
  /* justify-content: center; */
  /* text-align: right; */
  /* font-size: 3rem; */
  list-style-type: none;
  transition: filter 300ms;
  align-self: center;
  cursor: pointer;
  height: fit-content;
  /* margin-left: 0.25rem; */
}

.UI-icon-button {
  /*may not be used for 3dot menu*/
  width: var(--button-size);
  height: var(--button-size);
  padding: 0.31rem;
  margin: 0.13rem;
  display: flex;
}

.mute-icon {
  padding-right: 0.5rem;
  cursor: default;
  margin-right: 0.1rem;
}

.mute-tooltip{
    position: absolute;
    margin: 0;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-size: 0.9rem;
    transform: translate(-2rem, -0.15rem);
    border: 1px var(--menu_border_color) solid;
    border-radius: var(--border_radius_small);
    background-color: var(--menu_background_color);
    color: var(--menu_text_color);
    box-shadow:var(--box_shadow);
    /* box-shadow: 0px 0.25rem 0.625rem -0.25rem rgba(0,0,0,0.3); */
}

.mute-icon-none {
  display: none;
}

@media screen and (max-width: 4500px) {
  .mute-icon {
    font-size: 0.9rem;
    padding: 0;
    margin-right: 0.1rem;
  }
}

@media screen and (max-width: 290px) {
  .mute-icon {
    font-size: 0.7rem;
    padding: 0;
    margin-right: 0.1rem;
  }
}

.dots-icon-button {
  /*for 3dots menu*/
  position: absolute;
  justify-self: right;
  /* margin-left: auto; this is now on the mute icon*/
  /* margin-right: 0.5rem; */
}

.icon-button:hover {
  filter: brightness(1.2);
}

/* Does not work with react-icons (needs inline), only a formatting guide.*/
.UI-header-bar-icons {
  width: 1.5em; 
  height: 1.5em;
}
P
.UI-topic-menu {
  max-width: 1.13rem;
  max-height: 1.13rem;
  background-size: 1.13rem 1.13rem;
  flex-grow: 1;
}

.UI-topic-link-with-t {
  width: 100%;
  /* max-width: 60%; */
  /* place-self: center; */
  color: var(--topic_text_t_color);
  text-decoration: none;
  /* height:40px; */
  /* color: #354402; */
}

.UI-topic-link {
  width: 100%;
  place-self: center;
  color: var(--main_screen_text_color);
  text-decoration: none;
  /* color: #354402; */
}

.UI-dialog-link {
  width: 100%;
  place-self: center;
  color: var(--main_screen_text_color);
  text-decoration: none;
}

.UI-topic-inner {
  display:flex;
  align-items:center;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.rgtContainer{
display:flex;
flex-direction: row;
align-items: center;
margin-left: auto;
justify-content: flex-end;
width:15rem;
}

.UI-topic-tdn {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: flex-start;
  font-size: 1.1rem;
  line-height: 1.2em;
  position:relative;
  width:100%;
}


/* .UI-topic-tdn {
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: flex-start;
  font-size: 1.1rem;
  line-height: 1.2em;
  max-height: calc(1.2em * 3);
  position:relative;
  width:100%;
} */

/* .UI-topic-tdn:hover {
  overflow: auto;
  word-break: normal;
  overflow-wrap: anywhere;
} */

.UI-topic-persona {
  grid-area: "persona";
  display: flex;
  justify-content: flex-start;
  margin-left: 0.31rem;
  margin-right: 0.31rem;
  white-space: nowrap;
  overflow: scroll;
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.UI-topic-persona:hover {
  overflow: auto;
  white-space: normal;
  word-break: normal;
  overflow-wrap: anywhere;
}
.UI-topic-count {
  /* grid-area: "count"; */
  background-color: var(--unread_message_indicator_background_color);
  color: var(--unread_message_indicator_text_color);
  width: 1.5rem;
  overflow: hidden;
  height: fit-content;
  justify-self: center;
  align-self: center;
  font-size: 0.95rem;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
}

.UI-topic-count[type="collection"] {
  background-color: var(--unread_topic_indicator_background_color);
  color: var(--unread_topic_indicator_text_color);
  width: 1.5rem;
  overflow: hidden;
  height: fit-content;
  justify-self: center;
  align-self: center;
  font-size: 0.95rem;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
}

.UI-topic-count[type="folder"] {
  margin-left: 1rem;
}

.UI-text-entry {
  border: 0.13rem solid #add036;
  border-radius: 0.13rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.UI-button-send {
  border: 0.13rem solid #add036;
  border-radius: 0.13rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.UI-message-list-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 16.25rem);
  min-width: calc(100% - 9.75rem);
  /* width: calc(100% - 2.25rem); */
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  overflow: hidden;
}

.UI-message-list {
  /* width: 100%; */
  overflow: auto;
  padding-right: 0.5rem;
  /* transform: rotate(180deg); */
}

.UI-message-list-imgloaded {
  display: none;
}

.UI-imgcontainer-imgloaded {
  text-align: center;
  margin: 8px 0 0 0;
  /* padding: 20px; */
  /* position: absolute; */
  /* bottom: 0; */
  left: 0;
  /* width: auto; */
  height: calc(100vh - 200px);
}

.messages-imgloaded {
  grid-area: messages;
  overflow: hidden;
  padding-top: 0.5rem;
  background-color: black;
  height: 100vh;
}

.at-persona-list{
  position:absolute;
  bottom:0;
  transform:translate(0px, -50px);
  z-index:999;
  color:var(--menu_text_color);
  background-color: var(--menu_background_color);
  border:1px solid var(--menu_border_color);
  border-radius:var(--border_radius_medium);
  padding:0.5rem;
  text-align: left;
  max-height:10rem;
  overflow:hidden;
  overflow-y: scroll;
  list-style-type: none;
  box-shadow:var(--box_shadow);
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
animation: growDownArPersonas var(--speed) ease-in-out forwards;
opacity: "1";
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.at-personas{
  margin-bottom: 0.33rem;
  cursor: pointer;
}

.at-persona-list::-webkit-scrollbar {
display: none;
}

.at-personas:hover {
  background-color: var(--menu_background_selected_color);
  color: var(--menu_text_selected_color);
  /* background-color: #b3c1f8; */
}

@keyframes growDownAtPersonas {
0% {
  opacity: 0;
  transform: translate(0%, 0%);
}
100% {
  opacity: 1;
  transform: translate(-240px, 0%);
}
}

.sendbar-imgloaded {
  grid-area: messages;
  overflow: hidden;
  /* position: absolute; */
  bottom: 0;
  /* width: 100%; */
  margin-bottom: 25px;
  /* grid-column-start: 1;
  grid-column-end: 1; */
}

.img-style-imgloaded {
  /* display: none; */
  z-index: 1000;
}

.UI-message-list-item-right {
  display: flex;
  direction: ltr;
  justify-content: flex-end;
  text-align: right;
  background-color: #555555;
  max-width: 90%;
  margin-top: 0.19rem;
  margin-bottom: 0.19rem;
  margin-left: 1.88rem;
}

.UI-message-list-item-left {
  display: flex;
  direction: ltr;
  justify-content: flex-start;
  text-align: left;
  background-color: #d15d1a;
  max-width: 90%;
  margin-top: 0.19rem;
  margin-bottom: 0.19rem;
  margin-right: 1.88rem;
}

.UI-message-text {
  max-width: 95%;
  margin-left: 0.31rem;
  margin-right: 0.31rem;
}

.UI-message-area {
  display: inline-block;
  min-width: calc(17.5rem);
  width: 98%;
}

.UI-message-input {
  /* display:grid;
  grid-template-columns: min-content min-content 1fr min-content;
  align-items: center; */
  display: flex;
  flex-direction: row;
  align-items: center;
  clear: both;
}

.UI-camera-box {
  flex-grow: auto;
  max-height: 8.75rem;
}

.UI-video {
  height: 8.75rem;
  width: 8.75rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.UI-logo {
  height: 40vmin;
  pointer-events: none;
}

.UI-message-holder {
  overflow: auto;
}

.sticky-note {
  background-color: #ffc;
  color: #000;
  display: block;
  min-height: 4rem;
  min-width: 4rem;
  height: fit-content;
  max-width: 80%;
  padding: 0.5rem;
  box-shadow:var(--box_shadow);
  /* box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7); */
  transform: rotate(-4deg);
  margin-right: 1rem;
  margin-top:0.5rem;
}

.sticky-note-body {
  white-space: "pre-wrap";
  word-break: break-word;
  margin-top: 0;
  text-align: left;
  font-style: italic;
  font-size: 0.88rem;
  margin-bottom: 0.13rem;
}

.UI-message {
  display: flex;
  flex-direction: row;
  clear: both;
  direction: ltr;
  /* margin-top: 0.19rem; */
  border: 0.13rem solid #dedede;
  border-radius: var(--border_radius_large);
  padding-top: 0.5rem;
  padding-bottom:0.25rem;
  padding-right:1.5rem;
  padding-left: 0.5rem;;
  overflow: visible;
  min-height: 20px;
  min-width: 7rem;
}

.UI-message-subgroup{
  position: relative;
  display: flex;
  flex-direction: row;
  clear: both;
  direction: ltr;
  margin-bottom: 0.25rem;
  border: 0.13rem solid #dedede;
  border-radius: var(--border_radius_large);
  padding-top: 0.5rem;
  padding-bottom:0.25rem;
  padding-right:0.5rem;
  padding-left: 0.5rem;;
  overflow: visible;
  min-height: 20px;
}

/* .UI-message-subgroup[type="left"]{
   text-align: left;
   background-color: var(--receive_bubble_background_color);
   border-color: var(--receive_bubble_border_color);
   color: var(--receive_bubble_text_color);
   max-width: 94%;
  display: flex;
  flex-direction: row;
  clear: both;
  direction: ltr;
  margin-bottom: 0.25rem;
  border: 0.13rem solid #dedede;
  border-radius: var(--border_radius_large);
  padding-top: 0.5rem;
  padding-bottom:0.25rem;
  padding-right:0.5rem;
  padding-left: 0.5rem;;
  overflow: visible;
  min-height: 20px;
} */

.UI-message-whisper[type="left"] {
  display: flex;
  flex-direction: row;
  /* clear: both; */
  direction: ltr;
  /* max-width: 90%; */
  margin-top: 0.19rem;
  /* margin-bottom: 0.19rem; */
  /* border: 0.13rem solid #dedede; */
  border-radius: var(--border_radius_large);
  /* width: fit-content; */
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow: visible;
  min-height: 20px;

  text-align: left;
  margin-right: 0.25rem;
  background-color: var(--receive_bubble_background_color);
  border-color: var(--receive_bubble_border_color);
  color: var(--receive_bubble_text_color);
  min-width: 20%;
  max-width: 94%;
  width: fit-content;
}

.UI-message-whisper[type="right"] {
  display: flex;
  flex-direction: row;
  clear: both;
  direction: ltr;
  /* max-width: 90%; */
  margin-top: 0.19rem;
  /* margin-bottom: 0.19rem; */
  /* border: 0.13rem solid #dedede; */
  border-radius: var(--border_radius_large);
  /* width: fit-content; */
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow: visible;
  min-height: 20px;

  /* direction: rtl; */
  justify-content: flex-start;
  /* text-align: right; */
  margin-left: 1.88rem;
  background-color: var(--send_bubble_background_color);
  border-color: var(--send_bubble_border_color);
  color: var(--send_bubble_text_color);
  min-width: 20%;
  max-width: 80%;
  /* width: fit-content; */
  float: right;
}

.UI-message-container[type="left"] {
  display: flex;
  padding-top:0.25rem;
  padding-bottom:0.25rem;
  flex-direction: column;
  /* flex-direction: row;
  align-items: flex-end; */
    /*touch-action: none; prevents scrolling on certain phones, needed for pointer events */
}

.UI-message-container[type="right"] {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top:0.25rem;
  padding-bottom:0.25rem;
  /*touch-action: none; prevents scrolling on certain phones, needed for pointer events */
}

.UI-message-container[type="system"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UI-message-container[type="center"] {
  display: flex;
  padding-top:0.25rem;
  padding-bottom:0.25rem;
  flex-direction: column;
  width:100%;
  align-items: center;
  /* justify-content: center;
  margin-left:50%;
  transform: translateX(-50%);
  max-width: 90%; */
}

.UI-message-container[type="community"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UI-message-avatar-name-container[type="left"] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.UI-message-avatar-name-container[type="service"] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: 90%; */
}

.UI-message-avatar-name-container[type="right"] {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.UI-message-avatar-name-container[type="center"] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin-left:50%;
  transform:translateX(-50%); */
  width:90%;
  max-width: fit-content;
}

.UI-message-avatar-name-container[type="system"] {
  max-width: 80%;
}

.UI-message[type="left"] {
  position:relative;
  text-align: left;
  background-color: var(--receive_bubble_background_color);
  border-color: var(--receive_bubble_border_color);
  color: var(--receive_bubble_text_color);
  max-width: 94%;
  width: fit-content;
  white-space: pre-wrap;
}

.UI-message[type="center"] {
  position:relative;
  text-align: left;
  background-color: var(--receive_bubble_background_color);
  border-color: var(--receive_bubble_border_color);
  color: var(--receive_bubble_text_color);
  max-width: 94%;
  width: 90%;
  white-space: pre-wrap;
}

.UI-message[type="service"] {
  text-align: left;
  background-color: var(--receive_bubble_background_color);
  border-color: var(--receive_bubble_border_color);
  color: var(--receive_bubble_text_color);
  overflow: hidden;
  margin-bottom: 8px;
  position:relative;
}

.UI-message[type="systemcenter"] {
  justify-content: center;
  text-align: center;
  background-color: var(--system_bubble_background_color);
  border-color: var(--system_bubble_border_color);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border_radius_medium);
  color: var(--system_bubble_text_color);
  width: fit-content;
  margin-top: 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 0.85rem;
}

.UI-message-datecenter {
  justify-content: center;
  background-color: var(--system_bubble_background_color);
  border-color: var(--system_bubble_border_color);
  border-style: solid;
  border-width: 1px;
  border-radius: var(--border_radius_medium);
  color: var(--system_bubble_text_color);
  min-width: 20%;
  width: fit-content;
  margin-top: 0.25rem;
  margin-bottom: 0;
  margin-left: 50%;
  transform: translate(-50%);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  font-size: 0.85rem;
}

.UImessage-display-none {
  display: none;
}

.UI-message[type="right"] {
  /* direction: rtl; */
  position:relative;
  justify-content: flex-start;
  text-align: right;
  /* margin-left: 1.88rem; */
  background-color: var(--send_bubble_background_color);
  border-color: var(--send_bubble_border_color);
  color: var(--send_bubble_text_color);
  /* min-width: 20%; */
  max-width: 90%;
  /* width: fit-content; */
  float: right;
  white-space: pre-wrap;
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* aspect-ratio: 1; */
}

.avatar-string {
  background: #5a218f;
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex-grow: auto;
  color: #ffffff;
   /* By default, the avatar-string is fully opaque */
   opacity: 1;
   /* The transition will apply to the 'opacity' property with a duration of 0.5 seconds */
   transition: opacity 2.5s;
}

.avatar-string.fade-in {
  /* background: #5a218f;
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex-grow: auto;
  color: #ffffff; */
   /* By default, the avatar-string is fully opaque */
   /* The transition will apply to the 'opacity' property with a duration of 0.5 seconds */
  opacity: 0;
}

.avatar-string[type="topic"] {
  width: 3.5rem;
  height: 3.5rem;
}

.avatar-string[type="header"] {
  width: 3.5rem;
  height: 3.5rem;
}

.avatar-string[type="persona"] {
  width: 3.5rem;
  height: 3.5rem;
  aspect-ratio: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border:2px solid var(--main_header_image_border_color);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  /* margin-top: 0.5rem;
  margin-bottom: 0.5rem; */
  flex-grow: auto;
  /* color: #ffffff; */
   /* By default, the avatar-string is fully opaque */
   opacity: 1;
   /* The transition will apply to the 'opacity' property with a duration of 0.5 seconds */
   transition: opacity 2.5s;
}

.avatar-string[type="job"] {
  width: 4rem;
  height: 4rem;
}

.avatar-string-header {
  background: #5a218f;
  width: 4rem;
  height: 4rem;
  aspect-ratio: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  flex-grow: auto;
  color: #ffffff;
}

.avatar-frame {
  position: relative;
  min-width: 1.5rem;
  min-height: 1.88rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  z-index: 10;
}

.avatar-frame-none[type="left"] {
  position: relative;
  /* min-width: 1.5rem;
  min-height: 1.88rem; */
  height: 0rem;
  z-index: 10;
}

.avatar-frame-none[type="right"] {
  position: relative;
  transform: translate(0px, 4px);
  /* min-width: 1.5rem;
  min-height: 1.88rem; */
  height: 0rem;
  z-index: 10;
}

.avatar-frame-none[type="center"] {
  position: relative;
  transform: translate(0px, 4px);
  /* min-width: 1.5rem;
  min-height: 1.88rem; */
  height: 0rem;
  z-index: 10;
}

.UI-message .avatar {
  max-width: 3.25rem;
  max-height: 3.25rem;
  aspect-ratio: 1;
  width: 100%;
  border-radius: 50%;
  /* margin: 0.225rem; */
  margin-top: 0.225rem;
  margin-bottom: 0.225rem;
  margin-left: 0.225rem;
  margin-right: 1.5rem;
  flex-grow: auto;
}

.UI-message .avatar[type="left"] {
  float: left;
}

/* Style the right image */
.UI-message .avatar[type="right"] {
  float: right;
}

.UI-message_preview {
  min-height: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: 0rem;
  font-size: 0.9rem;
  max-height: 2rem;
  overflow: hidden;
  border:none;
  border-radius: 0%;
  padding:0;
  color:var(--main_screen_text_preview_color);
  line-height: 1.2;
  white-space: pre-wrap;
  word-break: break-word;
}

.UI-message .content {
  font-size: 1.1rem;
  /* flex-grow: 1; */
  direction: ltr;
  flex-wrap: wrap;
  /* margin-left: 0.5rem; */
  /* margin-right: 1rem; */
  /* margin-top: 0.5rem; */
  /* max-width: 80%; */
  display:flex;
  /* padding-left:0.625rem; */
  flex-direction: column;
}

.UI-message-content-body-system {
  white-space: "pre-wrap";
  word-break: break-word;
  margin-top: 0;
  text-align: left;
  font-size: 0.85rem;
}

.UI-message-content-body {
  white-space: "pre-wrap";
  word-break: break-word;
  margin-top: 0;
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 0.13rem;
}

/* .UI-message-content-body[type="emojis"] {
  font-size: 4rem;
}

.UI-message-content-body-system[type="emojis"] {
  font-size: 4rem;
} */

.emoji {
  font-family: "Segoe UI Emoji";
}

.UI-message-content-body-system[type="false"] {
  margin-top: 0.25rem;
}

.UI-message-content-body[type="false"] {
  margin-top: 0.25rem;
}

.content-system {
  font-size: 0.85srem;
  flex-grow: 1;
  direction: ltr;
  flex-wrap: wrap;
  margin-left: 8px;
  margin-right: 8px;
}

.UI-message .content p {
  margin-top: 0.13rem;
  margin-bottom: 0.13rem;
  padding: 0;
  word-break: break-word;
  margin-right: 0.25rem;
  white-space: normal;
}

.UI-persona-name {
  color: var(--receive_bubble_name_color);
  margin-left: 0.5rem;
  margin-bottom: 0;
  margin-top: 1rem;
  /* bottom: 0;
  top: 1.25rem; */
  font-weight: bold;
}

.UI-message .content .name {
  font-size: 0.75rem;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.UI-message .content .subtitle {
  font-size: 0.88rem;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.UI-message-whisper-subtitle {
  font-size: 0.88rem;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.time-style[type="left"] {
  display: flex;
  align-items: flex-end;
  font-size: var(--message_time_font_size);
  font-style: italic;
  margin-top: 0;
  color: var(--receive_bubble_time_color);
}

.time-style[type="right"] {
  float: right;
  font-size: var(--message_time_font_size);
  font-style: italic;
  margin-top: 0;
  margin-right: var(--border_radius_large);
  color: var(--send_bubble_time_color);
}
.time-style[type="center"] {
  margin-left: auto;
  font-size: var(--message_time_font_size);
  font-style: italic;
  margin-top: 0;
  margin-right: var(--border_radius_large);
  color: var(--send_bubble_time_color);
}

.time-style[type="system"] {
  float: right;
  font-size: var(--message_time_font_size);
  font-style: italic;
  margin-top: 0;
  margin-right: var(--border_radius_large);
  color: var(--system_bubble_time_color);
}

.UI-message .content .time-left {
  color: var(--receive_bubble_date_color);
  font-size: 0.65rem;
  font-style: italic;
  white-space: nowrap;
  /* display: flex;
  flex-direction: row;
  clear: both;
  direction: ltr;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin-left: 1.25rem;
  margin-right: 1.25rem; */
}

.UI-message .content .time-right {
  color: var(--send_bubble_date_color);
  font-size: 0.65rem;
  font-style: italic;
  white-space: nowrap;
  /* display: flex;
  flex-direction: row;
  clear: both;
  direction: ltr;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin-left: 1.25rem;
  margin-right: 1.25rem; */
}

.UI-message .thumbnail {
  display: block;
  margin-bottom:0.5rem;
  max-width: 100%;
  cursor: pointer;
  object-fit: contain;
}


.UI-message-subgroup .thumbnail {
  display: block;
  margin-bottom:0.5rem;
  max-width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .UI-logo {
    animation: UI-logo-spin infinite 5s linear;
  }
}

.UI-link {
  color: #61dafb;
}

@keyframes UI-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.UI-input {
  font-family: inherit;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

/* Full-width input fields */
.UI-input[type="subtitle"] {
  width: 100%;
  margin: 0.25rem 0;
  display: inline-block;
  border: 0.06rem solid #ccc;
  box-sizing: border-box;
  height: 1.6em;
  max-height: 1.6em;
  min-height: 1.6em;
}

#msg_text::placeholder {
  color: var(--send_bar_hint_color);
  opacity: 1;
}

.UI-input[type="message"] {
  width: 90%;
  margin: 0.25rem;
  border: 0.13rem solid var(--send_bar_border_color);
  background-color: var(--send_bar_background_color);
  color: var(--send_bar_text_color);
  border-radius: var(--border_radius_small);
  font-size: 1rem;
  line-height: 1rem;
  overflow: auto;
  height: auto;
  padding: 0.5rem;
  box-shadow:var(--box_shadow);
  /* box-shadow: 0px 0.25rem 0.625rem -0.5rem black; */
  resize: none;
  z-index: 9;
  font-size: 1.2rem;
}

.UI-messagesend-surround {
  width: 70%;
  border: 0.06rem solid var(--send_bar_border_color);
  background-color: var(--send_bar_background_color);
  border-radius: var(--border_radius_small);
  padding-top: 0.25rem;
  height: auto;
}

.UI-input[type="messagesend"] {
  /* font-size: 1rem; */
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  width: 95%;
  background-color: var(--send_bar_background_color);
  color: var(--send_bar_text_color);
  outline: none;
  border: none;
  line-height: 1.2rem;
  overflow: auto;
  /* padding-left: 0.25rem;
  padding-right: 0.25rem; */
  /* padding: 0.5rem; */
  padding-left: 0rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  height: auto;
  resize: none;
  z-index: 9;
  font-size: 1.2rem;
  margin: 0;
}

.UI-input[type="uid"],
.UI-input[type="password"],
.UI-input[type="topic"] {
  width: 90%;
  min-width: 200px;
  padding: 0.5rem;
  margin: 0.25rem;
  display: inline-block;
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_small);
  box-sizing: border-box;
  margin-left: 0.75rem;
  /* margin-bottom: 1rem; */
  padding: 0.25rem;
}

.UI-input[type="color"] {
  width: 1.88rem;
}

.UI-message-buttons {
  /* display: none; */
  /* display: flex; */
  /* flex-direction: row;
  justify-items: space-between; */
}

.gallery_btn {
  left: 0;
}

/* Set a style for all buttons */
.UI-button {
  background-color: var(--button_background_color);
  color: var(--button_text_color);
  padding: 0.38rem;
  margin-top: 0.25rem;
  /* margin: 0.25rem 0.5rem; */
  border: 0.06rem var(--button_border_color) solid;
  cursor: pointer;
  min-width: 4.69rem;
  /* width: fit-content; */
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: var(--border_radius_small);
  box-shadow:var(--box_shadow);
  /* box-shadow: 0.13rem 0.19rem 0.13rem rgb(0 0 0 / 0.2); */
}

.UI-button-transparent {
  background-color: transparent;
  color: var(--main_screen_text_color);
  padding: 0.38rem;
  /* margin: 0.25rem 0.5rem; */
  border: 0.06rem var(--button_border_color) solid;
  cursor: pointer;
  min-width: 4.69rem;
  /* width: fit-content; */
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: var(--border_radius_small);
  box-shadow:var(--box_shadow);
  /* box-shadow: 0.13rem 0.19rem 0.13rem rgb(0 0 0 / 0.2); */
}

.UI-button-service {
  position:relative;
  background:linear-gradient(to top, var(--button_background_color),  var(--button_background_gradient_color));
  color: var(--button_text_color);
  border: 0.06rem var(--button_border_color) solid;
  min-width: 6rem;
  max-width: 6rem;
  height:1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border_radius_small);
  box-shadow:var(--box_shadow);
  text-align: center;
  margin-bottom: 0.5rem;
  /* display: flex;
  align-items: center; */
}

.UI-button-service:hover {
  background: linear-gradient(to top, var(--button_background_color_selected),  var(--button_background_gradient_color_selected));
  /* transform: translateY(-1px); */
  color: var(--button_text_color_selected);
  cursor: pointer;
}

.UI-button-service:disabled {
  background:linear-gradient(to top, var(--button_background_color), var(--button_background_color));
  opacity:0.5;
  cursor:auto;
  color: var(--button_text_color);
}

.UI-button-service::after {
  display:block;
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  /* opacity:0; */
  transition:all 0.5s;
  box-shadow:var(--box_shadow);
  /* box-shadow: 0.13rem 0.19rem 0.13rem rgb(0 0 0 / 0.2); */
  /* animation: btn 2s infinite; */
  /* color: var(--button_text_color); */
  padding: 0.38rem;
  border: 0.06rem var(--button_border_color) solid;
  cursor: pointer;
  min-width: 6rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border_radius_small);
}

.UI-button-service:active:after {
  /* content:"clicked"; */
  /* background-color: red;
  opacity:0.75; */
  transform:scale(0.98);
  transition:all 0.5sec;
  box-shadow:var(--box_shadow);
  /* box-shadow: 0.06rem 0.06rem 00.06rem rgb(0 0 0 / 0.2); */
  /* transition: 0s; */
  /* animation: btn 2s infinite; */
  /* animation-name: btnpulse;
  animation-duration: 0.5s;
  animation-iteration-count: 6; */
}

/* .UI-button-service:focus { */
  /* content:"clicked"; */
  /* background-color: teal; */
  /* opacity:0.25; */
  /* transition:all 0.5sec;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 0.2);
  transition: 0s; */
  /* animation-name: btnpulse;
  animation-duration: 0.5s; 
  animation-iteration-count: 6; */
  /* animation: btn 2s infinite; */
/* } */

/* @-webkit-keyframes btnpulse {
  0% {
    background-color:var(--button_background_color);
  }
  50% {
    background-color:red;
  }
  100% {
    background-color: var(--button_background_color);
  }
}
@keyframes btnpulse {
  0% {
    background-color: var(--button_background_color);
  }
  70% {
    background-color: red;
  }
  100% {
    background-color:var(--button_background_color);
  }
} */


.UI-button-service_preview {
  position:relative;
  background-color: var(--button_background_color);
  color: var(--button_text_color);
  padding: 0.38rem;
  border: 0.06rem var(--button_border_color) solid;
  cursor: pointer;
  min-width: 6rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border_radius_small);
  box-shadow:var(--box_shadow);
  /* box-shadow: 0.13rem 0.19rem 0.13rem rgb(0 0 0 / 0.2); */
  opacity:0.5
}

.UI-button-service.preview {  
  font-size: 0.7rem;
  min-width: 3.5rem;
}

/* .UI-button-service[type="default"] {
  background-color: var(--button_background_color);
  color: var(--button_text_color);
  border: 0.06rem var(--button_border_color) solid;
  cursor: pointer;
  min-width: 6rem;
  max-width:6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border_radius_small);
  box-shadow:var(--box_shadow);
}
.UI-button-service.preview[type="default"] {
  font-size: 0.7rem;
  min-width: 4.5rem;
} */

.UI-button-service[type=greyOpacity] {
  background-color: var(--button_background_color);
  color: var(--button_text_color);
  padding: 0.38rem;
  border: 0.06rem var(--button_border_color) solid;
  cursor: pointer;
  min-width:6rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border_radius_small);
  box-shadow:var(--box_shadow);
  /* box-shadow: 0.13rem 0.19rem 0.13rem rgb(0 0 0 / 0.2); */
  opacity:0.5;
}

.ezist_btn {
  height: 4.25rem;
  width: 4.25rem;
  border-radius: 50%;
  border: none;
  box-shadow: 2px 2px 8px 0px rgba(0 0 0 / 0.45);
}

.ezist_btn.preview {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: none;
  margin-right: 0.5rem;
  opacity: 0.5;
}

.ezist_btn_individual{
  font-size: 2rem;
color: white;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

.ezist_btn[type=greyOpacity]{
  opacity:0.5;
}

.UI-button-greyed {
  background-color: var(--button_background_color);
  opacity: 0.5;
  color: var(--button_text_color);
  padding: 0.38rem;
  margin: 0.25rem 0.5rem;
  border: 0.06rem var(--button_border_color) solid;
  cursor: pointer;
  min-width: 4.69rem;
  width: fit-content;
  border-radius: var(--border_radius_small);
  box-shadow:var(--box_shadow);
  /* box-shadow: 0.13rem 0.19rem 0.13rem rgb(0 0 0 / 0.2); */
}

.UI-button-confined {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: white;
  background:linear-gradient(to top, var(--button_background_color),  var(--button_background_gradient_color));
  color: var(--button_text_color);
  padding: 0.25rem;
  margin: 0.25rem 0.5rem;
  border: 0.06rem var(--button_border_color) solid;
  min-width: 4.69rem;
  border-radius: var(--border_radius_large);
  font-size: 0.82rem;
  box-shadow:var(--box_shadow);
  display:flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.UI-button-confined:hover {
  background: linear-gradient(to top, var(--button_background_color_selected),  var(--button_background_gradient_color_selected));
  color: var(--button_text_color_selected);
  cursor: pointer;
}

.UI-button-whisper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: white;
  color: var(--button_text_color);
  padding: 0.25rem;
  margin: 0.25rem 0.5rem;
  border: 0.06rem var(--button_border_color) solid;
  min-width: 4.69rem;
  border-radius: var(--border_radius_large);
  font-size: 0.82rem;
  box-shadow:var(--box_shadow);
  display:flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.sign-in-email-buttons{
  display:flex;
  flex-direction: column;
  align-items: flex-end;
}

.UI-theme-buttons {
  padding: 0.625rem;
  align-self: center;
}

.UI-button[type="login"] {
  /* width: 45%; */
  min-width: 4.69rem;
  /* margin: 4px; */
  /* padding: 0.13rem; */
  align-self: center;
}

.UI-button[type="message"] {
  width: 100%;
  margin: 0.38rem;
  padding: 0.44rem 0.625rem;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 1rem;
  line-height: normal;
  font-family: Arial;
}

.UI-button[type="submit"] {
  width: 100%;
}

.UI-button[type="dropdown"] {
  background-color: #333333;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  font-size: 0.75rem;
  border-radius: 0.75rem;
  padding: 0.06rem;
  margin: 0;
  width: 3.13rem;
  min-width: 3.13rem;
  max-width: 3.13rem;
}

.UI-button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--send_button_background_color);
  color: #ffffff;
  border: none;
  cursor: pointer;
  background-size: 1.38rem 1.38rem;
  background-repeat: no-repeat;
  height: 2.5rem;
  width: 2.5rem;
  background-position: center;
  padding: 0.13rem;
  margin: 0.25rem;
  border-radius: 50%;
  z-index: 9;
}

.UI-button-icon[type="more"] {
  height: 1.13rem;
  width: 1.13rem;
  background-size: 100% 100%;
  background-image: url(./icons/outline_more_vert_white_48dp.png);
}

.UI-button-icon[type="my_dialog"] {
  height: 2.25rem;
  width: 2.25rem;
  background-size: 100% 100%;
  background-image: url(./icons/outline_share_white_48dp.png);
}

.UI-button-icon[type="share"] {
  height: 1.13rem;
  width: 1.13rem;
  background-size: 100% 100%;
  background-image: url(./icons/outline_share_white_48dp.png);
}

.UI-button-icon[type="delete"] {
  height: 1.13rem;
  width: 1.13rem;
  background-size: 100% 100%;
  background-image: url(./icons/outline_delete_white_48dp.png);
}

.UI-button-icon[type="login"] {
  background-image: url(./icons/outline_login_white_48dp.png);
}

.UI-button-icon[type="new_user"] {
  background-image: url(./icons/outline_person_add_alt_1_white_48dp.png);
}

.UI-button-icon[type="topic_search"] {
  background-image: url(./icons/outline_search_white_48dp.png);
}

.UI-button-icon[type="persona_search"] {
  background-image: url(./icons/outline_person_search_white_48dp.png);
}

.UI-button-icon[type="send"] {
  width: 3rem;
  background-image: url(./icons/outline_send_white_48dp.png);
}

.UI-button-icon[type="image"] {
  background-image: url(./icons/outline_add_photo_alternate_white_48dp.png);
}

.UI-button-icon[type="photo"] {
  background-image: url(./icons/outline_add_a_photo_white_48dp.png);
}

/* .UI-button-icon[type="file"] {
  background-image: url(./icons/outline_login_white_48dp.png);
} */

.UI-button-icon[type="file"] {
  background-image: url(./icons/document_white.png);
}

.UI-button-icon[type="file-throb"] {
  background-image: url(./icons/document_white.png);
  animation: pulse 2s infinite;
}

.UI-icondropdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;  
  width:1.1rem;
}

.UI-icon-dropdown-list {
  list-style-type: none;
  background-color: #777777;
  position: absolute;
  z-index: 3000;
  top: 1.13rem;
  opacity: 0.9;
  margin: 0;
  padding: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  white-space: nowrap;
}
/* 
.UI-buttondropdown {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  transform: translate(0, 20px);
} */

.UI-buttondropdown-1 {
  color: #ffffff;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  /* transform: translate(0, 20px); */
}

.UI-buttondropdown[type="left"] {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  /* right: 0; */
  margin: 0;
  padding: 0;
  transform: translate(0, 20px);
}

.UI-buttondropdown[type="right"] {
  /* color: #ffffff; */
  position: absolute;
  /* bottom: 0; */
  right: 0;
  top:0;
  margin: 0;
  padding: 0;
  /* transform: translate(0, 20px); */
}

.UI-buttondropdown[type="center"] {
  color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  transform: translate(0, 20px);
}
.UI-message-menu {
  background-color: var(--menu_background_color);
  color: var(--menu_button_icon_color);
  border: 1px solid var(--menu_button_border_color);
  border-radius: 50%;
}

.UI-dropdown-icons {
  position:absolute;
  right:1.8rem;
  border: 1px solid black;
  border-radius: var(--border_radius_small);
  z-index: 99;
  overflow: hidden;
  box-shadow:var(--box_shadow);
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  background-color: var(--menu_background_color);
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 0.1rem;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  animation: slideIn var(400ms) ease-in-out forwards;
}

@keyframes slideIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.UI-modal-icon {
  color: var(--ui_color);
}

.UI-dropdown-icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: var(--nav_icon_color);
  display:flex;
  align-items: center;
}

.UI-dropdown-list {
  position: absolute;
  list-style-type: none;
  background-color: var(--menu_background_color);
  color: var(--menu_text_color);
  border: var(--menu_border_color) 0.06rem solid;
  z-index: 99;
  margin: 0;
  padding: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  white-space: nowrap;
  border-radius: var(--border_radius_medium);
  box-shadow:var(--box_shadow);
  max-height: 70vh;
  /* max-width: 150px; */
  overflow: hidden;
  overflow-y: auto;
  /* box-shadow: 2px 4px 8px 0px rgb(0, 0, 0, 0.2); */
}
.UI-dropdown-list::-webkit-scrollbar {
  /* Customize the width and height of the scrollbar */
  /* width: 8px; */
  height: 8px;
}

.UI-dropdown-list::-webkit-scrollbar-thumb {
  /* Customize the appearance of the scrollbar thumb */
  background-color: #aaa;
  /* border-radius: var(--border_radius_medium); */
  border-top-right-radius: var(--border_radius_medium);
  border-bottom-right-radius: var(--border_radius_medium);
}

.UI-dropdown-list::-webkit-scrollbar-track {
  /* Customize the appearance of the scrollbar track */
  background-color: #f1f1f1;
  border-radius: var(--border_radius_medium);
}

.UI-dropdown-list-left {
  list-style-type: none;
  background-color: var(--menu_background_color);
  color: var(--menu_text_color);
  border: var(--menu_border_color) 0.06rem solid;
  position: absolute;
  z-index: 3000;
  top: 1.13rem;
  right:0;
  /* opacity: 0.9; */
  margin: 0;
  padding: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  white-space: nowrap;
  border-radius: var(--border_radius_medium);
  transform: translate(-20px, 0px);
}

.UI-dropdown-list-right {
  list-style-type: none;
  background-color: var(--menu_background_color);
  color: var(--menu_text_color);
  border: var(--menu_border_color) 0.06rem solid;
  position: absolute;
  z-index: 3000;
  top: 1.13rem;
  /* opacity: 0.9; */
  margin: 0;
  padding: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  white-space: nowrap;
  border-radius: var(--border_radius_medium);
  transform: translate(-156px, 0px);
}

.UI-dropdown-header{
  margin-top: 0.5rem;
  font-weight: bold;
}

.UI-dropdown-item {
  font-size: 0.88rem;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.UI-dropdown-item-filters {
  font-size: 0.88rem;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 180px;
}

.UI-dropdown-item:hover {
  background-color: var(--menu_background_selected_color);
  color: var(--menu_text_selected_color);
}

.UI-button-icon:hover {
  opacity: 0.8;
}

.UI-button:hover {
  opacity: 0.8;
}

.UI-choice-group .UI-choice {
  background-color: #333333;
  color: #add036;
  padding: 0.13rem 0.5rem;
  text-align: left;
  text-decoration: none;
  font-size: 0.75rem;
  display: block;
  border-radius: 0.75rem;
  margin: 0.5rem 0.31rem;
}

.UI-sub-choice {
  background-color: #333333;
  color: #add036;
  padding: 0.13rem 0.5rem;
  text-align: left;
  text-decoration: none;
  font-size: 0.625rem;
  display: inline;
  border-radius: 0.75rem;
  margin: 0.25rem;
}

/* Center the image and position the close button */
.UI-imgcontainer {
  text-align: center;
  /* margin: 24px 0 12px 0; */
  /* padding: 20px; */
  padding-top: 25px;
  position: relative;
}

.UI-imgcontainer label {
  display: block;
  margin: 15px;
}

.UI-avatar {
  width: 40%;
  border-radius: 50%;
}

.UI-login-container {
  text-align: left;
  width: 100%;
  font-size: 1rem;
}

.UI-login-container-col3 {
  text-align: left;
  width: 100%;
  max-height:95vh;
  overflow: auto;
  scroll-behavior: smooth;
  padding-bottom: 1rem;
}

.UI-login-container h2 {
  margin-bottom: 0.5rem;
}

.UI-login-container h3 {
}

.UI-login-container h4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.UI-theme-select-container {
  height: 100%;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.UI-theme-select-container h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  margin-left: 1rem;
}

.UI-theme-select-container h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  margin-left: 1rem;
}

.UI-theme-content {
  overflow-y: scroll;
  max-height: 50vh;
  width: 100%;
}

/* The Modal (background) */
.UI-login-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh;
  /* height: calc(100vh - 3.75rem); Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Modal Content/Box */
.UI-login-modal-content {
  background-color: var(--modal_background_color);
  z-index: 2000; /* Sit on top */
  /* margin: 5% auto 15% auto;  */
  /* 5% from the top, 15% from the bottom and centered */
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_large);
  padding: 0.5rem;
  max-width: 80%;
  min-width: 60%;
  /* min-height: 80%; */
  color: var(--modal_text_color);
  box-shadow:var(--box_shadow);
  /* box-shadow: 1px 3px 10px rgb(10, 10, 10); */
}

.UI-login-modal-content-image {
  background-color: var(--modal_background_color);
  z-index: 2000; /* Sit on top */
  position: absolute;
  /* top: 0; */
  left: 50%;
  transform: translate(-50%);
  /* margin-top: 112px; */
  /* margin-left: 8px;
  margin-right: 4px; */
  /* margin: 5% auto 15% auto;  */
  /* 5% from the top, 15% from the bottom and centered */
  /* border: 0.06rem solid var(--modal_border_color); */
  border-radius: var(--border_radius_large);
  max-width: 100%;
  min-width: 20%;
  color: var(--modal_text_color);
  /* box-shadow: 1px 3px 10px rgb(10, 10, 10); */
}

.UI-login-modal-image {
  /* position: absolute;
  display: block;
  left: 50%;
  top: 25%;
  transform: translate(-50%);
  z-index: 99;
  transition: all 100ms ease-in-out;  */

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.UI-login-modal-content-choose {
  background-color: var(--modal_background_color);
  z-index: 2000; /* Sit on top */
  /* margin: 5% auto 15% auto;  */
  /* 5% from the top, 15% from the bottom and centered */
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_large);
  padding: 0.5rem;
  max-width: 31.25rem;
  min-width: 12.5rem;
  color: var(--modal_text_color);
  box-shadow:var(--box_shadow);
  /* box-shadow: 1px 3px 10px rgb(10, 10, 10); */
}

.UI-login-modal-content-theme {
  background-color: var(--modal_background_color);
  z-index: 2000; /* Sit on top */
  /* margin: 5% auto 15% auto; */
  /* 5% from the top, 15% from the bottom and centered */
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_large);
  overflow: auto;
  width: 80%;
  height: 90%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 0; */
  max-width: 31.25rem;
  min-width: 12.5rem;
}

.UI-login-modal-content h2 {
  margin-top: 0.75rem;
}

.UI-login-modal-content-theme h4 {
  margin-top: 0.25rem;
  margin-bottom: 0.13rem;
  margin-left: 1rem;
}

.UI-message-modal {
  background-color: #fefefe00;
  z-index: 2000; /* Sit on top */
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  max-width: 90%;
}

/* The Close Button (x) */
.UI-modal-close {
  position: absolute;
  right: 0;
  margin-right: 0.25rem;
  top: 0;
  color: #000;
  font-size: 2.19rem;
  font-weight: bold;
}

.UI-modal-close:hover,
.UI-modal-close:focus {
  color: red;
  cursor: pointer;
}

/* The Close Button (x) */
.UI-message-modal-close {
  position: absolute;
  right: 0.625rem;
  top: -2.5rem;
  color: #fefefe;
  font-size: 2.19rem;
  font-weight: bold;
}

.UI-message-modal-close:hover,
.UI-message-modal-close:focus {
  color: red;
  cursor: pointer;
}

/* Add Zoom Animation */
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s;
}

.UI-map-frame {
  z-index: 0;
  min-height: 20vh;
  width: 100%;
  background-color: #888;
  overflow: hidden;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
}

.UI-map {
  height: 20vh;
  width: 100%;
  overflow: auto;
  position: relative;
}

/* #map { width: 100%; height:100%; position: relative;} */

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.UI-switch-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.UI-switch {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 0.88rem;
  min-height: 0.88rem;
  width: 100%;
  margin: 0.13rem;
}

.UI-switch-tag {
  font-size: 0.625rem;
  font-family: Verdana, sans-serif;
  position: absolute;
  left: 2.5rem;
  bottom: 0.06rem;
  text-align: left;
}

.UI-slider {
  width: 2rem;
  border-radius: 0.625rem;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.UI-slider:before {
  border-radius: 50%;
  position: absolute;
  content: "";
  height: 0.625rem;
  width: 0.625rem;
  left: 0.19rem;
  bottom: 0.13rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.UI-switch input[type="checkbox"] {
  width: 1.63rem;
  opacity: 0;
}

.UI-switch input:checked + .UI-slider {
  background-color: #add036;
}

.UI-switch input:focus + .UI-slider {
  box-shadow: 0 0 0.06rem #add036;
}

.UI-switch input:checked + .UI-slider:before {
  -webkit-transform: translateX(1.06rem);
  -ms-transform: translateX(1.06rem);
  transform: translateX(1.06rem);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 0.06rem dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 7.5rem;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 0.38rem;
  padding: 0.31rem 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -3.75rem;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -0.31rem;
  border-width: 0.31rem;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.tooltip .tooltipinit {
  animation: 10s fadeIn;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@keyframes fadeIn {
  25% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.UI-frame h4 {
  padding: 0;
  margin: 0;
}

.UI-dropdown-text {
  position: absolute;
  width: 12.5rem;
  transform: translate(-1.88rem, 1.88rem);
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: var(--menu_background_color);
  border-color: var(--menu_border_color);
  color: var(--menu_text_color);
  border: 0.06rem solid;
  border-radius: 0.31rem;
  font-size: 1rem;
  z-index: 99;
  right: 0;
}

.UI-dropdown-icon-with-text {
  font-size: 1.5rem;
  margin-right: 0.625rem;
}

.UI-dropdown-text-with-icon {
  font-size: 1rem;
}

.UI-dropdown-icon-text {
  margin: 0.25rem;
  padding: 0.25rem;
  color: var(--nav_icon_color);
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.UI-topic-avatar {
  display: flex;
  border-radius: 50%;
  align-self: center;
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  /* width: 4rem; */
}

.UI-topic-avatar[type="medium"] {
  display: flex;
  border-radius: 50%;
  align-self: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  /* width: 4rem; */
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.UI-topic-avatar[type="small"] {
  width: 0%;
  margin-left: 1rem;
}

.UI-topic-avatar[type="large"] {
  display: flex;
  border-radius: 50%;
  align-self: center;
  margin-left: 0.125rem;
  margin-right: 0.25rem;
  /* margin-top: 0.25rem;
  margin-bottom: 0.25rem; */
}
.UI-topic-avatar[type="xlarge"] {
  display: flex;
  border-radius: 50%;
  align-self: center;
  margin-left: 0.125rem;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.UI-suite-frame {
  background-color: var(--topic_screen_background_color);
  padding: 3.75%;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 0;
  grid-row-gap: 0.625rem;
  height: 100vh;
  overflow: hidden;
}

.UI-topic-grid {
  width: 100%;
  height: calc(100% - 2rem);
  display: grid;
  row-gap: 0.625rem;
}

.UI-topic-container {
  width: calc(100% - var(--column_divider_width) - var(--column_divider_width));
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content auto;
  grid-template-areas:
    "header"
    "pins"
    "messages";
  /*  "sendbar"; */
  row-gap: 0.13rem;
  overflow: hidden;
  border: var(--topic_border_color) solid 0.13rem;
  /* border-radius: var(--border_radius_large); */
  background-color: var(--topic_screen_background_color);
}

.no-pins {
  grid-template-rows: min-content auto max-content;
  grid-template-areas:
    "header"
    "messages"
    "sendbar";
  /* grid-template-rows: max-content auto auto; */
}

.header {
  grid-area: header;
  overflow: hidden;
}

.pins {
  grid-area: pins;
  overflow: hidden;
  max-height: 80vh;
  background-color: var(--topic_pinned_message_background_color);
  border-bottom: 0.13rem solid var(--receive_bubble_border_color);
}

.messages {
  grid-area: messages;
  overflow: hidden;
  padding-top: 0.5rem;
  max-height: calc(100vh - 8rem);
  padding-bottom: 0.2rem;
}

.sendbar {
  grid-area: sendbar;
  overflow: hidden;
  bottom: 0;
  /* margin-bottom: 20px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.4rem;
}

.UI-msg-list-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  padding-left: 0.5rem;
}

.ui-avatar-modal {
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}

.ui-avatar-modal-sub {
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.ui-invite-grid{
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: max-content;
  justify-items: start;
  width: 100%;
  font-size: 0.85rem;
  }
  

  @media (max-width: 500px) {
  .UI {
    grid-template-rows: max-content;
    grid-template-columns: 1fr;
  }

  .UI-frame {
    border-right: none;
  }

  .sendbar {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  /* .UI-button-service {
    background-color: var(--button_background_color);
    color: var(--button_text_color);
    padding: 0.38rem;
    border: 0.06rem var(--button_border_color) solid;
    cursor: pointer;
    min-width:6rem;
    max-width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: var(--border_radius_small);
    box-shadow:var(--box_shadow);
  }
  .UI-button-service.preview {
    min-width: 3.5rem;
  } */
  
  /* .UI-button-service[type="default"] {
    background-color: var(--button_background_color);
    color: var(--button_text_color);
    padding: 0.38rem;
    border: 0.06rem var(--button_border_color) solid;
    cursor: pointer;
    min-width: 6rem;
    max-width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: var(--border_radius_small);
    box-shadow:var(--box_shadow);
  } */

  /* .UI-button-service.preview[type="default"] {
    font-size: 0.7rem;
    min-width: 5rem;
  } */
  .UI-topic-link-with-t{
    /* max-width:45%; */
  }
}

@media (max-width: 640px) {
.UI-button-service {
  position:relative;
  background:linear-gradient(to top, var(--button_background_color),  var(--button_background_gradient_color));
  color: var(--button_text_color);
  border: 0.06rem var(--button_border_color) solid;
  min-width: 5rem;
  max-width: 5rem;
  height:1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: var(--border_radius_small);
  box-shadow:var(--box_shadow);
}
}

@media (min-width: 501px) {
  .UI {
    grid-template-columns: minmax(500px,600px) 1fr;

    /* grid-template-columns: 500px 1fr; */
    grid-template-rows: max-content;
  }

  .UI-dropdown-text {
    position: relative;
    transform: translate(0px, 1.88rem);
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .UI-frame {
    grid-column-start: 1;
    grid-column-end: 1;
    border-right: var(--column_divider_color) var(--column_divider_width) solid;
  }

  .image-close {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .sendbar {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  /* .UI-topic-dots-menu-no-header {
    grid-column-start: 1;
    grid-column-end: 1;
  } */

  /* .UI-back-arrow-no-header {
    grid-column-start: 1;
    grid-column-end: 1;
  } */
}

@media (min-width: 1001px) {
  .UI {
    grid-template-columns:  minmax(500px, 500px) minmax(500px, 500px) 1fr;
    grid-template-rows: max-content;
    /* grid-template-columns: 1fr 501px 1fr;
    grid-template-columns: 501px 501px 1fr; */
  }

  .UI-frame {
    border-left: var(--column_divider_color) var(--column_divider_width) solid;
    border-right: var(--column_divider_color) var(--column_divider_width) solid;
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .UI-login-modal-content-image {
    grid-column-start: 2;
    grid-column-end: 2;
  }

  .image-close {
    grid-column-start: 2;
    grid-column-end: 2;
  }

  /* .UI-topic-dots-menu-no-header {
    grid-column-start: 2;
    grid-column-end: 2;
  } */

  /* .UI-back-arrow-no-header {
    grid-column-start: 2;
    grid-column-end: 2;
  } */

  /* .UI-login-modal-image {
    grid-column-start: 2;
    grid-column-end: 2;
  } */
  /* .UI-topic-header[type="medium"] {
    grid-column-start: 2;
    grid-column-end: 2;
  } */
}

.UI-test-container {
  position: absolute;
  grid-column-start: 1;
  grid-column-end: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 0.625rem);
  max-width: calc(100%);
  width: 100%;
  /* margin-top: 100px; */
  left: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  overflow: auto;
}

.persona-screen-layout {
  /* position: absolute; */
  top: 0;
  left: 0;
  height: calc(100% - 120px);
  max-height: 100%;
  max-width: 100%;
  min-width: 450px;
  /* height: 600px; */
  /* transform: translateY(120px); */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  /* padding-right: 80px; */
  /* overflow: hidden; */
  /* width: 100%; */
  grid-column-start: 1;
  grid-column-end: 1;
}

.persona-screen-layout h2 {
  margin-bottom: 0;
}

.persona-screen-layout p {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}

/* @media (min-width: 80rem) {
  .persona-screen-layout {
    grid-column-start: 2;
    grid-column-end: 2;
  }
} */

.persona-scroll {
  /* width: 85%; */
  display:flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: left;
  min-width: calc(100% - 0.625rem);
  max-width: 100%;
  border-radius: 8px;
  padding-left: 1.5rem;
}

.persona-scroll:before,
.persona-scroll:after {
  content: "";
  width: 10px;
}

.dispnone {
  display: none;
}

.close {
  background-color: transparent;
  position: absolute;
  color: #333;
  top: 0;
  right: 0;
  font-size: 35px;
  border: none;
  z-index: 99;
  font-weight: bold;
}

.close-attachment {
  /* text-align: right; */
  background-color: transparent;
  color: red;
  font-size: 1.5rem;
  border: none;
  z-index: 99;
  /* font-weight: bold; */
}

.close-attachment:hover {
  color: red;
  cursor: pointer;
}

.image-close {
  position: absolute;
  display: flex;
  display: -moz-box;
  display: -webkit-flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 2.5rem;
  aspect-ratio: 1;
  border: 1px solid var(--modal_border_color);
  -webkit-border-radius: 50%; 
  -moz-border-radius: 50%; 
  border-radius: 50%; 
  background-color: lightgray;
  color: var(--modal_text_color);
  text-align: center;
  overflow: hidden;
}

.image-close:hover {
  color: red;
  cursor: pointer;
}

.link-text {
  color: "blue";
  text-decoration: "none";
  word-wrap: "break-word";
}

.img-style {
  /* display: none; */
  position: absolute;
  /* top: 100px; */
  z-index: 1000;
}

/* #canvas {
  width: 100%;
} */

.responsive-image {
  position: relative;
  max-width: 100%;
}

.responsive-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.attachment {
  font-size: 0.8rem;
  border: 1px solid var(--topic_border_color);
  border-radius: var(--border_radius_medium);
  background-color: var(--topic_screen_background_color);
  max-height: 5rem;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0 1rem 0 1rem;
}

.attachment-incl {
  /* white-space: pre-wrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  border: var(--modal_border_color);
  border-width: 1px;
  border-style: solid;
  border-radius: var(--border_radius_medium);
  /* (var--border_radius_medium); */
  cursor: pointer;
  width: fit-content;
  max-width: 100%;
  padding:0 0.5rem 0 0.5rem;
}

.attachment-item{
  display: grid;
  grid-template-columns: 10% 80%;
  gap: 0.5rem;
  align-content: center;
  align-items: center;
  position: relative;
  font-size: 0.9rem;
  margin: 0.25rem 0 0.25rem 0;
}

.suite-name {
  top: 0;
  color: var(--topic_header_text_color);
  background-color: var(--topic_header_background_color);
  border: var(--topic_border_color) solid 0.13rem;
  border-radius: var(--border_radius_medium);
  max-height: 5rem;
  font-size: 1.5rem;
  align-items: center;
  padding-top: 2px;
}

.UI-persona-search-list {
  display: grid;
  grid-template-columns: 125px 1fr;
  text-overflow: ellipsis;
  grid-gap: 12px;
  justify-items: start;
  white-space: nowrap;
  color: var(--modal_text_color);
  font-size: 1rem;
}

.UI-persona-search-list-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 125px;
}

.UI-topic-search-list {
  display: grid;
  grid-template-columns: 70% 30%;
  column-gap:1rem;
  grid-template-rows: min-content min-content;
  row-gap: 0.1rem;
  width:90%;
  color: var(--modal_text_color);
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.UI-topic-search-list-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.UI-topic-search-list-description {
  text-indent: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  grid-column: 1 / span 2;
}

.UI-persona-search-list--flex {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}

.UI-persona-search-list-item-name--flex {
  /* display: flex; */
  font-size: large;
  font-weight: bold;
  white-space: nowrap;
  max-width: 80vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

.UI-persona-search-list-item-last-seen--flex {
  margin: 0;
  font-size: 13px;
  color: grey;
}

.flag-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.flag-right {
  position: absolute;
  top: 50px;
  right: 0;
  margin: 0;
  padding: 0;
}

.linkify {
  color: blue;
}
.linkify:hover {
  color: red;
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 var(--send_button_background_color);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(244,157,22, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 var(--send_button_background_color);
    box-shadow: 0 0 0 0 var(--send_button_background_color);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(244,157,22, 0);
      box-shadow: 0 0 0 10px rgba(244,157,22, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(244,157,22, 0);
      box-shadow: 0 0 0 0 rgba(244,157,22, 0);
  }
}

.textfield a{ 
  color: blue !important;
  text-decoration: underline !important;
}

.sticky-note-body a{ 
  color: blue !important;
  text-decoration: underline !important;
}

.UI-message-content-body a{
  color: blue !important;
  text-decoration: underline !important;
}

.UI-message-content-body ul{
  list-style:disc;
  list-style-type:disc;
  list-style-position:inside;
}

.UI-message-content-body li{
  position:relative;
  /* white-space: pre-wrap; */
  margin:0px;
  padding-left: 1em;
}

.world_id_holder{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
}

.world_id_input{
  display: grid;
  grid-template-columns: 80% 10% 10%;
  align-items:center;
}


span.textbox {
  min-width: 80%;
  padding: 0.25rem;
  margin-right: 0.5rem;
  display: inline-block;
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_small);
  margin-bottom: 0.25rem;
 
    font-size: inherit;
    font-weight: inherit;
    /* height: "1.25rem", */
    display: flex;
    align-items: center;

  /* 
  line-height:20px;
  height:20px;
  padding:3px;
  border:1px #888 solid;
  font-size:1rem; */
}

span.textbox input {
  width:100%;
  border: 0px;
  background-color: #FFF;
}

.worldIDInfo-input{
  display: grid;
  grid-template-columns: 80% 10% 10%;
  align-items: center;
}