:root {
}

.pinnedMsg {
  position: absolute;
}

.lines {
  font-size: 1.2rem;
  /* display: flex;
  flex-direction: row; */
  /* max-width: 90%; */
  /* margin-left: 0.75rem; */
  /* margin-right: 0.75rem; */
  /* margin-top: 4px; */

  /* display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 4px;
  margin-left: 15px;
  margin-bottom: 10px; */
  /* white-space: nowrap;
  font-size: 14px; */
}

.lines-inner {
  /* margin-left: 16px; */
  max-width: 50%;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
}

.select-box {
  margin-bottom: 1px;
}

.halfLine {
  width: 50%;
  justify-content: left;
  align-items: left;
  left: 0;
}

.fields-in-rows {
  display: flex;
  flex-direction: row;
}

.markdown_Container{
  white-space: pre-wrap;
}

.textfield {
  /* font-size: var(--service_message_fontsize); */
  /* margin-bottom: 0px; */
  margin:0px;
  /* margin-top: 0.25rem; */
  /* margin-left: 15px; */
  /* white-space: pre-wrap; */
  /* max-width: 164px; */
}

.textfield ul{
  list-style:disc;
  list-style-type:disc;
  list-style-position:inside;
} 

.textfield p {
  /* white-space: pre-wrap; */
  margin-top:0rem;
}

.textfield li{
  position:relative;
  white-space: pre-wrap;
  margin:0px;
  padding-left: 1em;
}

.textfield li::before  {
  position:absolute;
  left:0;
  top:0.2em;
  white-space: pre-wrap;
  margin:0px;
  padding-left: 1em;
}
.textfield.preview {
  /* font-size: calc(0.8*var(--service_message_fontsize)); */
  max-height: 2rem;
   width: 100%;
   overflow: hidden;
   font-size: 0.9rem;
   color: var(--main_screen_text_preview_color);
   white-space: pre-wrap;
   overflow-wrap: break-word;
}

.right-align-label {
  text-align: right;
  font-weight: normal;
}

.left-align-label {
  font-size: var(--service_message_fontsize);
  text-align: left;
  font-weight: normal;
  display:flex;
  flex-direction: row;
  align-items: center;
}

/* .rbtn {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
} */

/* Style the custom circle */
/* .rbtn + label {
  position: relative;
  padding-left: 1.7rem; 
  cursor: pointer;
  display:flex;
  align-items: center;
} */
/* 
.rbtn + label::before {
  content: "";
  position: absolute;
  left: 0.25rem;
  top: 50%;
  transform: translateY(-50%); 
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid var(--button_border_color,#333333); 
  border-radius: 50%;
  background-color: transparent; 
} */

/* .rbtn:checked + label::before {
  background-color: var(--button_background_color_selected,#007bff);
  border-color: var(--button_border_color,#007bff); 
} */

/* .radio-header {
  text-align: left;
  margin-left: 2rem;
  font-size: 1rem;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
} */

.linesAcross {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.button-layout {
  /* margin-top: 16px; */
  /* margin-bottom: 0.5rem; */
  padding-top: 0.125rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 6rem);
  grid-gap: 0.688rem;
}

.button-layout-service {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-top: 0.125rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 6rem);
  grid-gap: 0.5rem;
}

.button-layout-service.preview {
  grid-template-columns: repeat(auto-fill, 4rem);
  opacity: 0.5;
}

.button-layout-service[type="pop-up"]  {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-top: 0.125rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 6rem);
  grid-gap: 0.5rem;
}
.button-layout-service.preview[type="pop-up"] {
  grid-template-columns: repeat(auto-fill, 3rem);
}

/* .button-layout-service[type="button"]  {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-top: 0.125rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 6rem);
  grid-gap: 0.5rem;
} */
/* .button-layout-service.preview[type="button"] {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding-top: 0.1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 6rem);
  grid-gap: 0.4rem;
} */

.ezist-btn-layout {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  padding-top: 0.313rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 4.25rem);
  grid-column-gap: 1rem;
  grid-row-gap: 1.2rem;
  justify-content: space-evenly;
}


.ezist-btn-layout_preview {
  /* max-width: 90%; */
  display:grid;
   grid-template-columns: repeat(auto-fill, 2.5rem);
  grid-column-gap: 0.7rem;
  grid-row-gap: 1rem; 
  min-width:90%;
}

.ezist-btn-layout.preview {
  max-width: 100%;
  /* grid-template-columns: repeat(auto-fill, 2.5rem);
  grid-column-gap: 0.7rem;
  grid-row-gap: 1rem;
  margin-top:0;
  grid-row-gap: 1rem; */
}

.button {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-color: var(--menu_background_color);
  border-radius: 0.25rem;
  font-size: 11rem;
}

.progress-container {
  display: grid;
  grid-gap: calc(var(--gutter) * 2);
  align-items: center;
  /* grid-template-columns: max-content; */
  grid-template: auto;
  grid-auto-flow: column;
  /* grid-auto-columns: max-content; */
  padding-bottom: calc(0.75 * var(--gutter));
  max-width: 500px;
  min-width: 400px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.progress-item {
  display: flex;
  flex-direction: row;
}

.progress-line {
  margin-left: 4px;
  margin-right: 4px;
  width: 100%;
}

.progress-item-last {
  display: flex;
  flex-direction: row;
}

/* temp ovveride till input components are consistent */
.MuiInputLabel-formControl {
  top: 16px !important;
  left: 0;
  position: relative !important;
  margin: 0px !important;
}

.markdown code {
  display: block;
  white-space: pre-wrap;
  margin:0px;
}

.no-margins {
  margin: 0;
}

@media (max-width: 40rem) {

  .button-layout {
    /* margin-top: 16px; */
    /* margin-bottom: 0.5rem; */
    padding-top: 0.125rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, 5rem);
    grid-gap: 0.688rem;
  }
  
  .button-layout-service[type="pop-up"]  {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-top: 0.125rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, 5rem);
    grid-gap: 0.5rem;
  }
  .button-layout-service.preview[type="pop-up"] {
    grid-template-columns: repeat(auto-fill, 3rem);
  }

  .button-layout-service[type="button"]  {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding-top: 0.125rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, 5rem);
    grid-gap: 0.5rem;
  }
  .button-layout-service.preview[type="button"] {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    padding-top: 0.1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, 5rem);
    grid-gap: 0.4rem;
  }
 
  .icon path {
    stroke:#FFF
  }
  
}

p{
  margin:0;
}

/* label{
  padding-left:1rem;
} */

label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(var(--button_background_color) 0%,var(--button_background_color) 40%, var(--button_background_color_selected,#007bff) 50%, var(--button_background_color_selected,#007bff));
  border-color: var(--button_color_selected)
}
input[type="radio"] {
  color:var(--button_text_color_selected)
}
label > input[type="radio"]:checked + * {
  /* color: teal; */
  color:var(--button_text_color_selected)
}

