.fab-container{
  position: relative;
}

.fab{
  /* position:sticky; */
  display:flex;
  align-items: center;
  justify-content: center;
  width:32px;
  height:32px;
  background-color: var(--search_fab_background_color);
  border: 2px solid var(--search_fab_border_color);
  border-radius: 50%;
  position:fixed;
  /* left:90%; */
  top:80%;
}
.fab:hover{
  background-color: var(--button_background_color);
  color:var(--button_text_color);
}