
.utils-label{
  text-align: left;
  font-weight: bold;
  font:inherit;
  font-size: inherit;
}
.utils-label[type="false"]{
 opacity:0.5;
}

.utils-input {
  min-width: 80%;
  padding: 0.25rem;
  /* margin-right: 0.5rem; */
  display: inline-block;
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_small);
  margin-bottom: 0.25rem;
  font:inherit;
  font-size: inherit;
}

.utils-input-with-clear {
  min-width: 85%;
  margin-right: 0.5rem;
  padding-left: 0.25rem;
  display: inline-block;
  font:inherit;
  font-size: inherit;
}

.utils-input-noMrgn {
  min-width: 80%;
  padding: 0.25rem;
  margin-right: 0.5rem;
  display: inline-block;
  border: 0.06rem solid var(--modal_border_color);
  border-radius: var(--border_radius_small);
  font:inherit;
  font-size: inherit;
}

.required:after {
  content:" *";
  color: red;
}

.activefalse:after {
  opacity:0.5;
}

.description{
  text-align: left;
  font-style:italic;
  margin-top:0;
  margin-bottom: 0.25rem;
}

.clearContent {
  background-color: transparent;
  color: #333;
  top: 0;
  right: 0;
  font-size: 28px;
  border: none;
  z-index: 99;
  cursor:pointer;
}

.clearContent:hover,
.clearContent:focus {
  color: red;
  cursor: pointer;
}

input:focus {
  outline-color:#000000;
}

.colors_display{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  max-width: 160px;
}

.color-options {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color:#000;
  margin-bottom: 0.25rem;
  text-align: left;
  /* flex-wrap: wrap;
  gap: 5px;
  justify-content: center; */
  margin-top: 20px;
}

.color-option {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.color-option:hover {
  transform: scale(1.2);
}

.color-option.selected {
  border: 2px solid #000;
}

.selected-color {
  margin-top: 20px;
}

.color-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 10px;
}