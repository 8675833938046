.skeleton{
  background:#ddd;
  /* margin:10px 0; */
  border-radius: 4px;
}

.skeleton.text{
  width:100%;
  height:12px;
}

.skeleton.title{
  width:50%;
  height:20px;
  /* margin-bottom: 15px; */
}

.skeleton.avatar{
  width:100%;
  height:100px;
  /* border-radius: 50%; */
}

.skeleton.thumbnail{
  width:100px;
  height:100px;
}

.skeleton.image{
  width:100%;
  height:100%;
  margin-bottom: 0.5rem;
  border-radius: 50%;
}

.skeleton-wrapper{
  position:relative;
  /* margin:10px 0 0 0; */
  border-radius: 4px;
  overflow:hidden;
}

.shimmer-wrapper{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  animation: loading 3.0s infinite;
}

.shimmer{
  width:5%;
  height:100%;
  background-color: rgba(250, 250, 250, 0.25);
  transform: skew(-20deg);
  box-shadow: 0 0 30px 30px rgba(250,250,250,0.25);
}

@keyframes loading{
0%{transform:translateX(-150%);}
/* 50% {transform: translateX(-60%)} */
100% {transform:translateX(150%);}
}
